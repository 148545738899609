import axios from '../utils/axiosInstance';
import {GetToken} from '../redux/Recaptcha'
import Cookies from 'js-cookie'
//import {removeCookie, setCookie, getCookie} from "../utils/session";
import {sendRequestDotNet} from '../utils/axiosdotnet'
import { authHeader } from '../helpers/auth-header';

export function login(request) {
  return GetToken('login').then(function (token) {
    //request.token=token;
    //Headers.recaptcha
    // const headers = {
    //   'recaptcha': token,
    //   //'recaptcha': "6Lfg34McAAAAAACihpookQdtxE34L0Ex78Yt6vVo",
    // }
  return sendRequestDotNet('/api/User/Login', request,{
    'recaptcha': token,
  })
    .then(function (response) {
      
      if (response.data.statusCode.code == 0) {
        Cookies.set("jwt", response.data.user.jwtToken);
      }

      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
  });
}

export function signUp(request) {
  // return GetToken('signUp').then(function (token) {
  //console.log(GetToken('Login'));
  return GetToken('signUp').then(function (token) {
  // return axios.post('/api/User/Signup', request,{
  //   'recaptcha': token,
  // })
  return sendRequestDotNet('/api/User/Signup', request,{
    'recaptcha': token,
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
  });
}

export function forgotPassword(request) { 
  return GetToken('forgotPassword').then(function (token) {
  return sendRequestDotNet('/api/User/forgotPassword', request,{
    'recaptcha': token,
  })
  // return axios.post('/api/User/forgotPassword', request)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
  });
}
export function changePassword(request) {
  // return GetToken('forgotPassword').then(function (token) {
  //console.log(GetToken('Login'));
  return GetToken('changePassword').then(function (token) {
  // return axios.post('/api/User/ChangePassword', request,{
  //   'recaptcha': token,
  // })
  return sendRequestDotNet('/api/User/ChangePassword', request,{
    'recaptcha': token,
    
  })
    .then(function (response) { 
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
  });
}

export function ValidateUser(request) {  
  return sendRequestDotNet('/api/User/ValidateUser', request) 
    .then(function (response) {  
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

export function logout() {
  return axios.post('/logout')
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

