export const ADD_TODO = "ADD_TODO";
export const FETCH_TODO_LIST = "FETCH_TODO_LIST";
export const TODO_LIST_FETCHED_SUCCESSFULLY = "TODO_LIST_FETCHED_SUCCESSFULLY";
export const TODO_LIST_FETCH_FAILED = "TODO_LIST_FETCH_FAILED";

export const LOGIN_REQUEST = 'USERS_LOGIN_REQUEST';
export const SIGN_UP="SIGN_UP";
export const SIGN_UP_SUCCESS="SIGN_UP_SUCCESS";
export const SIGN_UP_FAILURE="SIGN_UP_FAILURE";
export const LOGIN_SUCCESS = 'USERS_LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'USERS_LOGIN_FAILURE';
export const LOGOUT = 'USERS_LOGOUT';
export const FORGOT_PASSWORD='FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS="FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE="FORGOT_PASSWORD_FAILURE";

export const CHANGE_PASSWORD='CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS="CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE="CHANGE_PASSWORD_FAILURE";

//
export const LOAD_APP = 'LOAD_APP';
export const LOAD_FOOTER = 'LOAD_FOOTER';
export const LOAD_FILTER = 'LOAD_FILTER';



//news

export const FETCH_MAIN_NEWS = 'FETCH_MAIN_NEWS';
export const FETCH_NEWS_PAGE = 'FETCH_NEWS_PAGE';
export const FETCH_SINGLE_NEWS = 'FETCH_SINGLE_NEWS';
export const FETCH_MAIN_NEWS_SUCCESS = 'FETCH_MAIN_NEWS_SUCCESS';
export const FETCH_NEWS_PAGE_SUCCESS = 'FETCH_NEWS_PAGE_SUCCESS';
export const FETCH_SINGLE_NEWS_SUCCESS = 'FETCH_SINGLE_NEWS_SUCCESS';
export const FETCH_MAIN_NEWS_FAILED = 'FETCH_MAIN_NEWS_FAILED';
export const FETCH_NEWS_PAGE_FAILED = 'FETCH_NEWS_PAGE_FAILED';
export const FETCH_SINGLE_NEWS_FAILED = 'FETCH_SINGLE_NEWS_FAILED';


//Events

export const FETCH_EVENTS_PAGE = "FETCH_EVENTS_PAGE";
export const FETCH_EVENT = "FETCH_EVENT";
export const FETCH_EVENTS_PAGE_SUCCESS = "FETCH_EVENTS_PAGE_SUCCESS";
export const FETCH_EVENT_SUCCESS = "FETCH_EVENT_SUCCESS";
export const FETCH_EVENTS_PAGE_FAILED = "FETCH_EVENTS_PAGE_FAILED";
export const FETCH_EVENT_FAILED = "FETCH_EVENT_FAILED";



//agencies

export const FETCH_AGENCIES = 'FETCH_AGENCIES';
export const FETCH_AGENCIES_SUCCESS = 'FETCH_AGENCIES_SUCCESS';
export const FETCH_AGENCIES_FAILED = 'FETCH_AGENCIES_FAILED';


//configuration

export const FETCH_CONFIGURATION = 'FETCH_CONFIGURATION';
export const FETCH_FOOTER_MENU = 'FETCH_FOOTER_MENU';
export const FETCH_SLIDER_DATA = 'FETCH_SLIDER_DATA';
export const FETCH_CONFIGURATION_SUCCESS = 'FETCH_CONFIGURATION_SUCCESS';
export const FETCH_FOOTER_MENU_SUCCESS = 'FETCH_FOOTER_MENU_SUCCESS';
export const FETCH_SLIDER_DATA_SUCCESS = 'FETCH_SLIDER_DATA_SUCCESS';
export const FETCH_CONFIGURATION_FAILED = 'FETCH_CONFIGURATION_FAILED';
export const FETCH_FOOTER_MENU_FAILED = 'FETCH_FOOTER_MENU_FAILED';
export const FETCH_SLIDER_DATA_FAILED = 'FETCH_SLIDER_DATA_FAILED';
export const FETCH_FILTER_META_DATA = 'FETCH_FILTER_META_DATA';
export const FETCH_FILTER_META_DATA_SUCCESS = 'FETCH_FILTER_META_DATA_SUCCESS';
export const FETCH_FILTER_META_DATA_FAILED = 'FETCH_FILTER_META_DATA_FAILED';


// SEARCH


export const CHANGE_FILTER_PARAMS = 'CHANGE_FILTER_PARAMS';
export const APPLY_CHANGE_FILTER_PARAMS = 'APPLY_CHANGE_FILTER_PARAMS';
export const FETCH_SEARCH_MODELS = 'FETCH_SEARCH_MODELS';
export const FETCH_SEARCH_MODELS_SUCCESS = 'FETCH_SEARCH_MODELS_SUCCESS';
export const FETCH_SEARCH_MODELS_FAILED = 'FETCH_SEARCH_MODELS_FAILED';



//SERVICE

export const CHANGE_COUNTRY_PARAM_IN_SERVICE = "CHANGE_COUNTRY_PARAM_IN_SERVICE";
export const CHANGE_SERVICE_PARAM_IN_SERVICE = "CHANGE_SERVICE_PARAM_IN_SERVICE";
export const CHANGE_SERVICE_FILTER_PARAMS = "CHANGE_SERVICE_FILTER_PARAMS";
export const CHANGE_CATEGORY_PARAM_IN_SERVICE = "CHANGE_CATEGORY_PARAM_IN_SERVICE";
export const FETCH_SERVICE_CATEGORIES = "FETCH_SERVICE_CATEGORIES";
export const FETCH_SERVICE_CATEGORIES_SUCCESS = "FETCH_SERVICE_CATEGORIES_SUCCESS";
export const FETCH_SERVICE_CATEGORIES_FAILED = "FETCH_SERVICE_CATEGORIES_FAILED";
export const FETCH_SERVICE_MODELS = "FETCH_SERVICE_MODELS";
export const FETCH_SERVICE_MODELS_SUCCESS = "FETCH_SERVICE_MODELS_SUCCESS";
export const FETCH_SERVICE_MODELS_FAILED = "FETCH_SERVICE_MODELS_FAILED";

//MODEL


export const FETCH_MODEL_DETAILS = "FETCH_MODEL_DETAILS";
export const FETCH_MODEL_DETAILS_SUCCESSFULLY = "FETCH_MODEL_DETAILS_SUCCESSFULLY";
export const FETCH_MODEL_DETAILS_FAILED = "FETCH_MODEL_DETAILS_FAILED";
export const CHANGE_MODEL_SERVICE = "CHANGE_MODEL_SERVICE";
export const CHANGE_MODEL_FILTER_PARAMS = "CHANGE_MODEL_FILTER_PARAMS";
export const FETCH_MODEL_SERVICE_DETAILS_SUCCESSFULLY = "FETCH_MODEL_SERVICE_DETAILS_SUCCESSFULLY";
export const FETCH_MODEL_SERVICE_DETAILS_FAILED = "FETCH_MODEL_SERVICE_DETAILS_FAILED";
export const FETCH_MONTHLY_MODELS = "FETCH_MONTHLY_MODELS";
export const FETCH_MONTHLY_MODELS_SUCCESSFULLY = "FETCH_MONTHLY_MODELS_SUCCESSFULLY";
export const FETCH_MONTHLY_MODELS_FAILED = "FETCH_MONTHLY_MODELS_FAILED";



// BOOKING
export const FETCH_LOCATIONS = "FETCH_LOCATIONS";
export const FETCH_LOCATIONS_SUCCESS = "FETCH_LOCATIONS_SUCCESS";
export const FETCH_LOCATIONS_FAILED = "FETCH_LOCATIONS_FAILED";
export const FETCH_PRICING_DETAILS = "FETCH_PRICING_DETAILS";
export const FETCH_PRICING_DETAILS_SUCCESS = "FETCH_PRICING_DETAILS_SUCCESS";
export const FETCH_PRICING_DETAILS_FAILED = "FETCH_PRICING_DETAILS_FAILED";

//PROFILE


export const FETCH_PROFILE = 'FETCH_PROFILE';
export const FETCH_PROFILE_SUCCESS = 'FETCH_PROFILE_SUCCESS';
export const FETCH_PROFILE_FAILED = 'FETCH_PROFILE_FAILED';

export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILED = 'UPDATE_PROFILE_FAILED';