import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import {FETCH_TODO_LIST} from '../actions/actions-types';
import {fetchTodoSuccess,fetchTodoFailed} from '../actions/todo.actions';
//import {Get_TodoList} from '../API/api'

 function* fetchTodoList() {
    try {
        const fetchedTodoList =[{title:'hello!',text:'world',assignee:'me',__type:'Todo'},
        {title:'hello!',text:'world',assignee:'other',__type:'Todo'}];// yield call(Get_TodoList);
        yield put(fetchTodoSuccess(fetchedTodoList));
    } catch (error) {
        yield put(fetchTodoFailed(error));
    }
}


 function* fetchTodoListSaga() {
    yield takeEvery(FETCH_TODO_LIST, fetchTodoList);
}

export default function* rootSaga() {
    yield all([fork(fetchTodoListSaga)]);
}