import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { LOGIN_REQUEST, LOGIN_SUCCESS, FORGOT_PASSWORD,LOGIN_FAILURE, SIGN_UP, SIGN_UP_SUCCESS, SIGN_UP_FAILURE,FORGOT_PASSWORD_SUCCESS,FORGOT_PASSWORD_FAILURE ,CHANGE_PASSWORD,CHANGE_PASSWORD_SUCCESS,CHANGE_PASSWORD_FAILURE} from '../actions/actions-types';
import { history } from '../store'
import { login, signUp, forgotPassword,changePassword} from '../../datasource/auth.datasource'

function* loginRequest(action) { 
    try { 
        let response = yield call(login, { email: action.payload.user.username, password: action.payload.user.password });

        if (response.statusCode.code === 0) { 
            yield put({ type: LOGIN_SUCCESS, payload:  response.user});
            if(!action.payload.isBooking)
            {
                history.push(action.payload.from);
            } 
        }
        else {
            yield put({ type: LOGIN_FAILURE, payload: response });
        }


    } catch (error) {
        yield put({ type: LOGIN_FAILURE, payload: { error } });
    }
}

function* signupRequest(action) {
    try { 
        const { firstName, lastName, phone, email, password } = action.payload.user;
        const request={
            firstName: firstName,
            lastName: lastName,
            msisdn: phone,
            email: email,
            password: password
        }; 
        let response = yield call(signUp, request);  
        if (response.statusCode.code === 0) {  
            localStorage.setItem('user',JSON.stringify( {
                "idUser": response.user.idUser,
                "displayName": response.user.displayName
              }) ); 
            yield put({ type: SIGN_UP_SUCCESS });
            yield put({ type: LOGIN_SUCCESS, payload:  response.user });
            if(!action.payload.isBooking)
            { 
            history.push('/en');
            }
        }
        else {
            yield put({ type: SIGN_UP_FAILURE, payload: { message: response.statusCode.message } });
        }

        //history.push(action.payload.from);

    } catch (error) {
        console.error(error);
        // yield put({ type: LOGIN_FAILURE, payload: { error } });
    }
}

// function* logoutRequest() {
//     localStorage.removeItem('user');
// }
function* forgotPasswordRequest(action) { 
    try {
      
        let response = yield call(forgotPassword, { email: action.payload});

        if (response.statusCode.code === 0) { 
            yield put({ type: FORGOT_PASSWORD_SUCCESS, payload: response });
        }
        else {
            yield put({ type: FORGOT_PASSWORD_FAILURE, payload: response });
        }


    } catch (error) {
        yield put({ type: LOGIN_FAILURE, payload: { error } });
    }
}
function* changePasswordRequest(action) { 
    try {
      
        let response = yield call(changePassword, action.payload.user); 
        if (response.statusCode.code === 0) { 
            yield put({ type: FORGOT_PASSWORD_SUCCESS, payload: response });
        }
        else {
            yield put({ type: FORGOT_PASSWORD_FAILURE, payload: response });
        }
 
    } catch (error) {
        yield put({ type: LOGIN_FAILURE, payload: { error } });
    }
}
function* loginRequestSaga() {
    yield takeEvery(LOGIN_REQUEST, loginRequest);
}

function* signUpRequestSaga() {
    yield takeEvery(SIGN_UP, signupRequest);
}
function* forgotPasswordRequestSaga() {
    yield takeEvery(FORGOT_PASSWORD, forgotPasswordRequest);
}

function* changePasswordRequestSaga(){
    yield takeEvery(CHANGE_PASSWORD,changePasswordRequest);
}
// function* logoutRequestSaga() {
//     yield takeEvery(LOGOUT, logoutRequest);
// }

export default function* rootSaga() {
    yield all([fork(loginRequestSaga), fork(signUpRequestSaga),fork(forgotPasswordRequestSaga),fork(changePasswordRequestSaga)]);

    // yield all([fork(logoutRequestSaga)]);
}