
import {
//    FETCH_MONTHLY_MODELS,
   FETCH_MONTHLY_MODELS_FAILED,
   FETCH_MONTHLY_MODELS_SUCCESSFULLY,
} from '../actions/actions-types'

const INIT_STATE = { 
    models:
    {
        loading: false,
        loaded: false,
        list: []
    }
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {


       

        // case FETCH_MONTHLY_MODELS: {
        //     return {
        //         ...state, models: { ...state.models, loaded: false, loading: true, list: [] }
        //     }

        // }
        case FETCH_MONTHLY_MODELS_SUCCESSFULLY: {
            return {
                ...state, models: { ...state.models, loaded: true, loading: false, list: action.payload.models }
            }

        }
        case FETCH_MONTHLY_MODELS_FAILED: {
            return {
                ...state, models: { ...state.models, loaded: true, loading: false }
            }

        }

        default:
            return state;
    }
}