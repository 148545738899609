import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { history } from '../../redux/store';
// import { matchPath } from "react-router";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import LoginLogo from "../../assests/images/login.png";
import { logout } from "../../redux/actions/auth.actions";

// const match = matchPath(
//     history.location.pathname,
//     { path: '/:lang' }
// );
import {VAR_LANG_ID,CAR_RENTAL,PROFILE_ROUTE,CHAUFFEUR_SERVICES,TRANSFER_SERVICES, RATES_ROUTE, NEWS_ROUTE,EVENT_ROUTE,SIGNUP_ROUTE,LOGIN_ROUTE} from '../../utils/settings';

class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openMenu: false
        }
    }

    listenScrollEvent = e => {
        if (window.scrollY > 1) {
            this.setState({ headerScrollClass: 'header-white-bgd' })
        }
        else {
            this.setState({ headerScrollClass: '' })

        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.listenScrollEvent)
    }

    headerClass = () => {
        switch (this.props.router.location.pathname) {
            case `/${LOGIN_ROUTE}`:
                return 'header-login';
            case `/${SIGNUP_ROUTE}`:
                return 'header-signup';
            case `/`:
                return 'header-home';
            default:
                return '';
        }
    }

    goToHomePage() {
        history.push(`/`);
    }

    toggleMenu = () => {
        let newState = !this.state.openMenu;
        document.getElementsByTagName("body")[0].className = newState ? "open-menu" : "";
        this.setState({ openMenu: newState });

    }
    search = () => {
        let newState = !this.state.openMenu;
        document.getElementsByTagName("body")[0].className = newState ? "open-menu" : "";
        this.setState({ openMenu: newState });
        // history.push(`/events?city=${"-1"}&event=${"-1"}`);
        history.push(`/${EVENT_ROUTE}`);
    }

    redirectToLogin = () => {
        this.toggleMenu();
        history.push(`/${LOGIN_ROUTE}`);
    }

    redirectToSignUp = () => {
        this.toggleMenu();
        history.push(`/${SIGNUP_ROUTE}`);
    }
    redirectToProfile = () => {
        this.toggleMenu();
        history.push(`/${PROFILE_ROUTE}`);
    }


    logout = () => {
        this.props.dispatch(logout());
        history.push(`/`) 
    }


    renderLanguageList = () => {

        // switch (match.params.lang) {
        //     case "fr":
        //         return (
        //             <li className="language">
        //                 <a><FormattedMessage id="header.french" /></a>
        //                 <ul>
        //                     <li><a rel="noopener noreferrer" href="/en"><FormattedMessage id="header.english" /></a></li>
        //                     <li><a rel="noopener noreferrer" href="/ru"><FormattedMessage id="header.russian" /></a></li>
        //                 </ul>
        //             </li>
        //         )

        //     case "ru":
        //         return (
        //             <li className="language">
        //                 <a><FormattedMessage id="header.russian" /></a>
        //                 <ul>
        //                     <li><a rel="noopener noreferrer" href="/en"><FormattedMessage id="header.english" /></a></li>
        //                     <li><a rel="noopener noreferrer" href="/fr"><FormattedMessage id="header.french" /></a></li>
        //                 </ul>
        //             </li>
        //         )
        //     default:
        //         return (
        //             <li className="language">
        //                 <a><FormattedMessage id="header.english" /></a>
        //                 <ul>
        //                     <li><a rel="noopener noreferrer" href="/fr"><FormattedMessage id="header.french" /></a></li>
        //                     <li><a rel="noopener noreferrer" href="/ru"><FormattedMessage id="header.russian" /></a></li>
        //                 </ul>
        //             </li>
        //         )
        // }


        // http://www.aaarentcars.com/
        // http://www.aaarentcars.fr/
        // http://www.aaarentcars.ru/

        ///// add specific language /////
        if(VAR_LANG_ID === 1) {
            return (
                <li className="language">
                    <a><FormattedMessage id="header.english" /></a>
                    <ul>
                        <li><a rel="noopener noreferrer" href="http://aaarentcars.fr/"><FormattedMessage id="header.french" /></a></li>
                        {/* <li><a rel="noopener noreferrer" href="http://51.68.82.32:8083/"><FormattedMessage id="header.russian" /></a></li> */}
                    </ul>
                </li>
            )
        }
        else if(VAR_LANG_ID === 2) {
            return (
                <li className="language">
                    <a><FormattedMessage id="header.french" /></a>
                    <ul>
                        <li><a rel="noopener noreferrer" href="http://aaarentcars.com/"><FormattedMessage id="header.english" /></a></li>
                        {/* <li><a rel="noopener noreferrer" href="http://www.aaarentcars.ru/"><FormattedMessage id="header.russian" /></a></li> */}
                    </ul>
                </li>
            )
        }
        else if(VAR_LANG_ID === 3) {
            return (
                <li className="language">
                    <a><FormattedMessage id="header.russian" /></a>
                    <ul>
                        <li><a rel="noopener noreferrer" href="http://aaarentcars.com/"><FormattedMessage id="header.english" /></a></li>
                        <li><a rel="noopener noreferrer" href="http://aaarentcars.fr/"><FormattedMessage id="header.french" /></a></li>
                    </ul>
                </li>
            )
        }
        else {
            return (
                <li className="language">
                    <a><FormattedMessage id="header.english" /></a>
                    <ul>
                        <li><a rel="noopener noreferrer" href="http://aaarentcars.fr/"><FormattedMessage id="header.french" /></a></li>
                        {/* <li><a rel="noopener noreferrer" href="http://51.68.82.32:8083/"><FormattedMessage id="header.russian" /></a></li> */}
                    </ul>
                </li>
            )
        }
        ///// add specific language /////
    }



    render() {
        let headerClass = this.headerClass();
        return (
            <div className={`header-wrapper ${headerClass} `}>
                <div className={`header-placeholder`}></div>
                <header style={{ backgroundColor: this.state.color }} className={`${this.state.openMenu ? "active-menu" : ""} ${this.state.headerScrollClass}`}>
                    <div onClick={this.goToHomePage} className="logo"></div>
                    {/* <img className="logo" onClick={this.goToHomePage} src="http://aaaimages.developmenthouse.net/logo.png" alt="AAA car rental logo" /> */}
                    <div className="menu">
                        <ul>
                            {this.props.authentication.loggedIn
                                ?  
                                     <li className="language">
                                     <a>{this.props.authentication.user.displayName}</a>
                                     <ul>
                                     <li><a onClick={() => { history.push(`/${PROFILE_ROUTE}`) }}><FormattedMessage id="header.profile" /></a></li>
                                     <li><a onClick={this.logout}><FormattedMessage id="header.logout" /></a></li>
                                     </ul>
                                 </li>
                                : <li><a onClick={() => { history.push(`/${LOGIN_ROUTE}`) }}><FormattedMessage id="header.signIn" /></a></li>
                            }
                            {this.renderLanguageList()}
                            <li onClick={this.toggleMenu}><a><FormattedMessage id="header.menu" /></a></li>
                        </ul>

                        <button className={`hamburger hamburger--spin ${this.state.openMenu ? 'is-active' : ''}`} type="button" onClick={this.toggleMenu}>
                            <span className="hamburger-box">
                                <span className="hamburger-inner"></span>
                            </span>
                        </button>

                    </div>

                    <nav className="side-menu">
                        <ul>
                            <li>
                                <NavLink onClick={this.toggleMenu} to={`/`}><FormattedMessage id="header.home" /></NavLink>
                            </li>
                            <li>
                                <NavLink onClick={this.toggleMenu} to={"/service/"+CAR_RENTAL}><FormattedMessage id="carRentalSideMenu" /></NavLink>
                            </li>
                            <li>
                                <NavLink onClick={this.toggleMenu} to={"/service/"+CHAUFFEUR_SERVICES}><FormattedMessage id="chaufferedSideMenu" /></NavLink>
                            </li>
                            <li>
                                <NavLink onClick={this.toggleMenu} to={"/service/"+TRANSFER_SERVICES}><FormattedMessage id="shuttleSideMenu" /></NavLink>
                            </li>
                            {/* <li>
                                <NavLink onClick={this.toggleMenu} to={`/service/4`}>Events Services</NavLink>
                            </li> */}
                            <li>
                                <NavLink onClick={this.toggleMenu} to={"/"+RATES_ROUTE+"/"+CAR_RENTAL}><FormattedMessage id="rates" /></NavLink>
                            </li>
                            <li>
                                <NavLink onClick={this.toggleMenu} to={"/"+NEWS_ROUTE}><FormattedMessage id="news" /></NavLink>
                            </li>
                            <li>
                                <p onClick={this.search}><FormattedMessage id="events" /></p>
                            </li>
                            <li>
                                <NavLink onClick={this.toggleMenu} to={`/contact`}><FormattedMessage id="header.contact" /></NavLink>
                            </li>
                        {this.renderLanguageList()}
                        </ul>
                        <div className="user-register">
                            <div className="user-register-wrapper">
                               {!this.props.authentication.loggedIn&& 
                            <div>
                                 <img src={LoginLogo} alt="user icon" />
                                <button onClick={this.redirectToSignUp}><FormattedMessage id="header.signUp" /></button>
                                <button onClick={this.redirectToLogin}><FormattedMessage id="header.login" /></button>
                            </div>
                            }
                             {this.props.authentication.loggedIn&& 
                            <div>
                                 <img src={LoginLogo} alt="user icon" />
                                <button onClick={this.redirectToProfile}><FormattedMessage id="header.profile" /></button>
                                <button onClick={this.logout}><FormattedMessage id="header.logout" /></button>
                            </div>
                            }
                                <div className="media-icons">
                                    <a target="_blank" href="https://www.facebook.com/aaa.luxury.sport.cars.rental"><i className="icon-fb"></i></a>
                                    <a target="_blank" href="https://plus.google.com/112432202757578439230/posts"><i className="icon-g"></i></a>
                                    <a target="_blank" href="https://www.instagram.com/aaarentcars/?hl=en"> <i className="icon-Insta"></i></a>
                                    <a target="_blank" href="https://www.youtube.com/user/3ALuxury"> <i className="icon-youtube"></i></a>
                                </div>
                            </div>

                        </div>
                    </nav>


                </header>
            </div>
        );
    }
}


function mapStateToProps({ authentication }) {  
    return { authentication };
}

export default connect(mapStateToProps)(index);