import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import {

    FETCH_PRICING_DETAILS,
    FETCH_PRICING_DETAILS_FAILED,
    FETCH_PRICING_DETAILS_SUCCESS,
    // FETCH_MODEL_SERVICE_DETAILS_SUCCESSFULLY
} from '../actions/actions-types';
import { getShuttlePricingDetails, getChaufeuredPricingDetails, getRentalPricingDetails } from '../../datasource/booking.datasource';
// import { getModelServiceDetails } from '../../datasource/model.datasource';


const getModelState = (state) => state.model;

function* fetchPricing(action) {
    try {
        // let modelState = yield select(getModelState);
        var response;;

        switch (action.payload.serviceId) {
            case "1":
                response = yield call(getRentalPricingDetails, { idModel: action.payload.modelId });
                break;
            case "2":
                response = yield call(getChaufeuredPricingDetails, { idModel: action.payload.modelId });
                break;
            case "3":
                response = yield call(getShuttlePricingDetails, { idModel: action.payload.modelId });
                break;
            default:
                response = yield call(getRentalPricingDetails, { idModel: action.payload.modelId });

        }
        yield put({ type: FETCH_PRICING_DETAILS_SUCCESS, payload: { pricing: response.servicesPrice, extraServices: response.extraServicePrice } });
    } catch (error) {
        yield put({ type: FETCH_PRICING_DETAILS_FAILED, payload: { error } });
    }
}




function* fetchPricingSaga() {
    yield takeEvery(FETCH_PRICING_DETAILS, fetchPricing);
}


export default function* rootSaga() {
    yield all([fork(fetchPricingSaga)]);
}