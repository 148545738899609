
import {
  ADD_TODO
} from "../actions/actions-types";
 
const TodoMiddleware = store => next => action => {
  if (action.type !== ADD_TODO) {
      // //console.log("action", action);
  }

  next(action);

};
export default TodoMiddleware;