import {
    // FETCH_AGENCIES,
    FETCH_AGENCIES_SUCCESS,
    FETCH_AGENCIES_FAILED,
} from '../actions/actions-types';


const initialState = {

    agencies: {
        loaded: false,
        loading: false,
        list: []
    },

    openedAgency: {
        loaded: false,
        loading: false,
        details: null
    }
}


export default function agenciesReducer(state = initialState, action) {
    switch (action.type) {
        // case FETCH_AGENCIES:{
        //     return { ...state, agencies: { ...state.agencies, loaded: false, loading: true, list: []} }

        // }

        case FETCH_AGENCIES_SUCCESS: {
            return { ...state, agencies: { ...state.agencies, loaded: true, loading: false, list: action.payload.agencies } }


        }


        case FETCH_AGENCIES_FAILED: {
            return { ...state, agencies: { ...state.agencies, loaded: false, loading: false } }
        }




        default:
            return state
    }
}