import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchFooterMenu } from '../../redux/actions/configuration.actions';
import Footer from '../../components/Footer/menu';

class FooterContainer extends Component {


    componentDidMount() {
        this.props.dispatch(fetchFooterMenu());

    }
    render() {
        let { loaded, data } = this.props.footerMenu;
        return (
            <div>
                {loaded && <Footer items={data}></Footer>}
            </div>

        );
    }
}

function mapStateToProps({ configuration }) {
    let { footerMenu } = configuration;
    return { footerMenu };
}

export default connect(mapStateToProps)(FooterContainer);