import {all} from 'redux-saga/effects';
import todoSaga from './todo.saga';
import authSaga from './auth.saga';
import newsSaga from './news.saga';
import profileSaga from './profile.saga'; 
import eventsSaga from './events.saga';
import agenciesSaga from './agencies.saga';
import appSaga from './app.saga';
import searchSaga from './search.saga';
import modelSaga from './model.saga';
import monthlySelectionsSaga from './monthlySelections.saga';
import bookingSaga from './booking.saga';


export default function* rootSaga(getState) {
    yield all([
        appSaga(),
        todoSaga(),
        authSaga(),
        newsSaga(),
        eventsSaga(),
        agenciesSaga(),
        searchSaga(),
        modelSaga(),
        monthlySelectionsSaga(),
        bookingSaga(),
        profileSaga()
    ]);
}
