//import { GOOGLE_RECAPTCHA_SITE_KEY } from '../Constants/Settings'
const GOOGLE_RECAPTCHA_SITE_KEY="6Lfg34McAAAAAACihpookQdtxE34L0Ex78Yt6vVo";

export function AddAction(actionName) {
    window.grecaptcha.execute(GOOGLE_RECAPTCHA_SITE_KEY, { action: actionName });
}

export function GetToken(actionName) {
    return window.grecaptcha.execute(GOOGLE_RECAPTCHA_SITE_KEY, { action: actionName });

}