import React, { Component } from 'react';
// import logo from './logo.svg';
import './assests/sass/main.css';
import './assests/sass/colors/color-08.css';
import store from './redux/store'
import { Provider } from 'react-redux';
import { addLocaleData } from 'react-intl';
import AppContainer from './containers/app'
import arLocaleData from 'react-intl/locale-data/ar'
import frLocaleData from 'react-intl/locale-data/fr'
import ruLocalData from 'react-intl/locale-data/ru'
import { IntlProvider } from 'react-intl-redux'


addLocaleData([...frLocaleData, ...arLocaleData,...ruLocalData])

class App extends Component {

  render() {
    return (
      <div className="App">
        <Provider store={store}>
          <IntlProvider textComponent={React.Fragment}>
            <AppContainer></AppContainer>
          </IntlProvider>
        </Provider>
      </div>
    );
  }
}

export default App;
