import {
     FETCH_CONFIGURATION ,
     FETCH_FOOTER_MENU ,
    //  FETCH_SLIDER_DATA,
     FETCH_FILTER_META_DATA,
     FETCH_CONFIGURATION_SUCCESS,
     FETCH_FOOTER_MENU_SUCCESS,
     FETCH_SLIDER_DATA_SUCCESS,
     FETCH_FILTER_META_DATA_SUCCESS,
     FETCH_CONFIGURATION_FAILED ,
     FETCH_FOOTER_MENU_FAILED ,
     FETCH_SLIDER_DATA_FAILED,
     FETCH_FILTER_META_DATA_FAILED,
     FETCH_LOCATIONS,
     FETCH_LOCATIONS_SUCCESS,
     FETCH_LOCATIONS_FAILED,
} from '../actions/actions-types';


const initialState = {

    configuration: {
        loaded: false,
        loading: false,
        data: null
    },

    filterMetaData:{
        loaded: false,
        loading: false,
        data: []
    },
   
    footerMenu: {
        loaded: false,
        loading: false,
        data: []
    },

    slider:{
        loaded: false,
        loading: false,
        data:[]
    },
    locations: {
        loaded: false,
        loading: false,
        list: []
    },

    whoWeAreText:''
}


export default function configurationReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_FOOTER_MENU:{
            return {...state,footerMenu:{...state.footerMenu,loaded:false,loading:true,data:[]}}
        }
           
        case FETCH_FOOTER_MENU_SUCCESS:{
            return {...state,footerMenu:{...state.footerMenu,loaded:true,loading:false,data:action.payload.menu}}
                   
        }
        case FETCH_FOOTER_MENU_FAILED:{
            return {...state,footerMenu:{...state.footerMenu,loaded:false,loading:false}}
        }
        case FETCH_CONFIGURATION:{
            return {...state,configuration:{...state.configuration,loaded:false,loading:true,data:[]}}
        }

          
        case FETCH_CONFIGURATION_SUCCESS:{
            return {...state,configuration:{...state.configuration,loaded:true,loading:false,data:action.payload.configuration},whoWeAreText:action.payload.whoWeAreText}
        }


          
        case FETCH_CONFIGURATION_FAILED:{
            return {...state,configuration:{...state.configuration,loaded:false,loading:false}}
        }

        // case FETCH_SLIDER_DATA:{
        //     return {...state,slider:{...state.slider,loaded:false,loading:true,data:[]}};
        // }

           
        case FETCH_SLIDER_DATA_SUCCESS:{
            return {...state,slider:{...state.slider,loaded:true,loading:false,data:action.payload.slider}};
           
        }

          
        case FETCH_SLIDER_DATA_FAILED:{
            return {...state,slider:{...state.slider,loaded:false,loading:false}};
        }

        case FETCH_FILTER_META_DATA:{
            return {...state,filterMetaData:{...state.filterMetaData,loaded:false,loading:true,data:[]}};
        }

           
        case FETCH_FILTER_META_DATA_SUCCESS:{
            return {...state,filterMetaData:{...state.filterMetaData,loaded:true,loading:false,data:action.payload.metaData}};
           
        }

          
        case FETCH_FILTER_META_DATA_FAILED:{
            return {...state,filterMetaData:{...state.filterMetaData,loaded:false,loading:false}};
        }

        case FETCH_LOCATIONS: {
            return { ...state, locations: { ...state.locations, loaded: false, loading: true, list: [] } }
        }


        case FETCH_LOCATIONS_SUCCESS: {
            return { ...state, locations: { ...state.locations, loaded: true, loading: false, list: action.payload.locations } }
        }


        case FETCH_LOCATIONS_FAILED: {
            return { ...state, locations: { ...state.locations, loaded: false, loading: false } }
        }

        default:
            return state
    }
}