import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import {
    LOAD_APP,
    LOAD_FOOTER,
    FETCH_FOOTER_MENU,
    LOAD_FILTER,
    FETCH_SLIDER_DATA,
    FETCH_CONFIGURATION_SUCCESS,
    FETCH_FOOTER_MENU_SUCCESS,
    FETCH_SLIDER_DATA_SUCCESS,
    FETCH_CONFIGURATION_FAILED,
    FETCH_SLIDER_DATA_FAILED,
    FETCH_FILTER_META_DATA,
    FETCH_FILTER_META_DATA_SUCCESS,
    FETCH_FILTER_META_DATA_FAILED,
    FETCH_LOCATIONS,
    FETCH_LOCATIONS_SUCCESS,
    FETCH_LOCATIONS_FAILED,

} from '../actions/actions-types';
import { getConfigurationData, getFooterMenu, getSliderData, getFilterMetaData, getLocations, getFooterConfigurationFilter} from '../../datasource/configuration.datasource'

const getSliderState = (state) => state.configuration.slider;


function* loadApp() {
    try {
        // const footerResponse = yield call(getFooterMenu);
        // yield put({ type: FETCH_FOOTER_MENU_SUCCESS, payload: { menu: footerResponse.footer } });

        // const filterMetaResponse = yield call(getFilterMetaData);
        // yield put({ type: FETCH_FILTER_META_DATA_SUCCESS, payload: { metaData: filterMetaResponse.combination } });

        const configurationResponse = yield call(getConfigurationData);
        yield put({ type: FETCH_CONFIGURATION_SUCCESS, payload: { configuration: configurationResponse.configuration,whoWeAreText:configurationResponse.whoWeAre } });

    } catch (error) {

        yield put({ type: FETCH_CONFIGURATION_FAILED, payload: { error } });
    }
}

function* loadFooter() {
    try {
        const footerResponse = yield call(getFooterMenu);
        yield put({ type: FETCH_FOOTER_MENU_SUCCESS, payload: { menu: footerResponse.footer } });

    } catch (error) {

        yield put({ type: FETCH_CONFIGURATION_FAILED, payload: { error } });
    }
}
function* loadFilter() {
    try {
        
        const filterMetaResponse = yield call(getFilterMetaData);
        yield put({ type: FETCH_FILTER_META_DATA_SUCCESS, payload: { metaData: filterMetaResponse.combination } });

    } catch (error) {

        yield put({ type: FETCH_CONFIGURATION_FAILED, payload: { error } });
    } 
}





function* fetchSliderData() {
    try {
        let sliderState = yield select(getSliderState);
        if (!sliderState.loaded) {
            const response = yield call(getSliderData);
            yield put({ type: FETCH_SLIDER_DATA_SUCCESS, payload: { slider: response.slider } });
        }

    } catch (error) {
        yield put({ type: FETCH_SLIDER_DATA_FAILED, payload: { error } });
    }
}

function* fetchFilterMetaData() {
    try {
        const response = yield call(getFilterMetaData);
        yield put({ type: FETCH_FILTER_META_DATA_SUCCESS, payload: { metaData: response.data } });
    } catch (error) {
        yield put({ type: FETCH_FILTER_META_DATA_FAILED, payload: { error } });
    }
}

function* fetchLocations() {
    try {

        const response = yield call(getLocations);
        yield put({ type: FETCH_LOCATIONS_SUCCESS, payload: { locations: response.locations } });
    } catch (error) {
       
          yield put({ type: FETCH_LOCATIONS_FAILED, payload: { error } });
    }
}


function* fetchSliderSaga() {
    yield takeEvery(FETCH_SLIDER_DATA, fetchSliderData);
}

function* loadAppSaga() {
    yield takeEvery(LOAD_APP, loadApp);
}
function* loadFooterSaga() {
    yield takeEvery(FETCH_FOOTER_MENU, loadFooter);
}
function* loadFilterSaga() {
    yield takeEvery(LOAD_FILTER, loadFilter);
}


function* fetchFilterMetaDataSaga() {
    yield takeEvery(FETCH_FILTER_META_DATA, fetchFilterMetaData);
}

function* fetchLocationsSaga() {
    yield takeEvery(FETCH_LOCATIONS, fetchLocations);
}


export default function* rootSaga() {
    yield all([fork(loadAppSaga)]);
    yield all([fork(loadFooterSaga)]);
    yield all([fork(loadFilterSaga)]);
    yield all([fork(fetchSliderSaga)]);
    yield all([fork(fetchFilterMetaDataSaga)]);
    yield all([fork(fetchLocationsSaga)]);
}