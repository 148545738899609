import axios from './axiosInstance';
import Cookies from 'js-cookie';


export const sendRequestDotNet = async(link, data = {}, headers = {}, method = "POST") => {

    let contentType = "application/json";
    if(data instanceof FormData)
        contentType = "multipart/form-data";
  
        
    headers = {
        ...headers,
        'content-type': contentType,
    }
    let uAu = Cookies.get("jwt");
    let uCo = Cookies.get("set-cookie");
    // console.log(uAu);
    // console.log(Cookies.get("jwt"));
    if(uAu!=undefined)
    {
        headers = {
            ...headers,
            post:{
                //Token:uId.replace(/\s/g, '').replace('+', ''),
                Authorization: "Bearer " + uAu,
                // withCredentials: true
            }
        }
    }
    // if(uCo!=undefined)
    // {   
             
    //     headersRefresh = {
    //         ...headers,
    //         post:{
    //             //Token:uId.replace(/\s/g, '').replace('+', ''),
    //             //Cookie: "refreshToken=" + uCo 
    //         }
    //     } 
    //     //headers.post['Token'] = 
    // }
    let axiosInst = null;
      if(method === "POST")
          axiosInst = axios.post(link, data, {headers: headers});
      else
          axiosInst = axios.get(link, {headers: headers});
  
      var is401=false;
      var inst= await  axiosInst.then((response) => {
          let MsgResponse = "Something went wrong! Please try again";
          if (typeof response.data !== "undefined") {
              if (typeof response.data.statusCode !== "undefined") {
                  if (typeof response.data.statusCode.message !== "undefined") {
                      MsgResponse = response.data.statusCode.message;
                  }
                  if (typeof response.data.statusCode.code !== "undefined" && (response.data.statusCode.code === 0||response.data.statusCode.code!==401)) {
                      return {
                          error: false,
                          data: response.data,
                      };
                  }
              }
  
              //// special if no statusCode ////
              if (typeof response.data.code !== "undefined") {
                  if (typeof response.data.message !== "undefined") {
                      MsgResponse = response.data.message;
                  }
  
                  if (typeof response.data.code !== "undefined" && response.data.code === 0) {
                      return {
                          error: false,
                          data: response.data,
                      };
                  }
              }
              //// special if no statusCode ////
          }
  
          return {
              error: true,
              message: MsgResponse,
          };
      })
      .catch((error) => {
              //console.log(error);
          let MsgResponse = "Something went wrong! Please try again";
          if (error.response.status === 401) {
            is401=true;
      //console.log(link,is401);
          }else
          return {
              error: true,
              message: MsgResponse,
              data: data,
          };
      });
      if(!is401)
      {
          return inst;
      }
      else{
        var jwtToken="";
        // var refToken=Cookies.get("jwtRefresh");
        // Cookies.set("jwt", refToken);
        // var refToken=Cookies.get("jwt");
                // console.log(Cookies.get("jwt"))
                // console.log(Cookies.get("refreshToken"))

        await  axios.post("/api/User/RefreshToken", {}, {headers: headers}).then((response) => {
            
           // console.log("RefreshToken::",response);
            if (response.data.statusCode.code == 0) {
                // console.log(response.data.user.jwtToken)
                Cookies.set("jwt", response.data.user.jwtToken);
                // Cookies.set("refreshToken", response.data.user.refreshToken);
                // console.log(Cookies.get("jwt"))
                jwtToken=response.data.user.jwtToken;
                //window.location.reload();
                //Router.replace(window.location.href);
            }    
            else
            {
              //window.location("/login")
              console.log("else 1")
            }   
            }).catch((error) => {
              console.log("else 1")
              //window.location("/login")
            });
  
            let contentType = "application/json";
            if(data instanceof FormData)
                contentType = "multipart/form-data";
        
                //////
        
            headers = {
                ...headers,
                'content-type': contentType
            }
        
            let uAu = Cookies.get("jwt");
            if(uAu!=undefined)
            {
                headers = {
                    ...headers,
                    post:{
                        //Token:uId.replace(/\s/g, '').replace('+', ''),
                        Authorization: "Bearer " + uAu 
                    }
                }        
                //headers.post['Token'] = 
            }
            let axiosInst = null;
            if(method === "POST")
                axiosInst = axios.post(link, data, {headers: headers});
            else
                axiosInst = axios.get(link, {headers: headers});
        
            return axiosInst.then((response) => {
                let MsgResponse = "Something went wrong! Please try again";
                if (typeof response.data !== "undefined") {
                    if (typeof response.data.statusCode !== "undefined") {
                        if (typeof response.data.statusCode.message !== "undefined") {
                            MsgResponse = response.data.statusCode.message;
                        }
                        if (typeof response.data.statusCode.code !== "undefined" && (response.data.statusCode.code === 0||response.data.statusCode.code!==401)) {
                            return {
                                error: false,
                                data: response.data,
                            };
                        }
                    }
        
                    //// special if no statusCode ////
                    if (typeof response.data.code !== "undefined") {
                        if (typeof response.data.message !== "undefined") {
                            MsgResponse = response.data.message;
                        }
        
                        if (typeof response.data.code !== "undefined" && response.data.code === 0) {
                            return {
                                error: false,
                                data: response.data,
                            };
                        }
                    }
                    //// special if no statusCode ////
                }
                return {
                    error: true,
                    message: MsgResponse,
                };
            })
            .catch((error) => {
                    //console.log(error);
                let MsgResponse = "Something went wrong! Please try again";
                if (error.response.status === 401) {
                  //window.location("/login")
                }else
                return {
                    error: true,
                    message: MsgResponse,
                    data: data,
                };
            });
    }
  }
  