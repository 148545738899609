import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import {

    CHANGE_FILTER_PARAMS,
    FETCH_SEARCH_MODELS,
    FETCH_SEARCH_MODELS_SUCCESS,
    FETCH_SEARCH_MODELS_FAILED,
    APPLY_CHANGE_FILTER_PARAMS,
    // FETCH_MODELS
} from '../actions/actions-types';
import { getModels } from '../../datasource/model.datasource';
import _ from 'lodash';

const getSearchState = (state) => state.search; 
function* changeParamHandler(action) {
    try {
        let request = {
            countryId: action.payload.country === "-1" ? 0 : action.payload.country,
            serviceId: action.payload.service === "-1" ? 0 : action.payload.service,
            brandId: action.payload.brand === "-1" ? 0 : action.payload.brand,
            categoryId: action.payload.category === "-1" ? 0 : action.payload.category,
            lastKey: '',
            count: 9,
            mode: 0
        };
        let searchState = yield select(getSearchState);
        if (!_.isEqual(searchState.filter, action.payload) || !searchState.models.loaded) {
            yield put({ type: APPLY_CHANGE_FILTER_PARAMS, payload: action.payload });
            const response = yield call(getModels, request);
            const fetchedModels = response;

            yield put({ type: FETCH_SEARCH_MODELS_SUCCESS, payload: { models: fetchedModels.models, totalCount: fetchedModels.totalCount } });
        }

    } catch (error) {

        yield put({ type: FETCH_SEARCH_MODELS_FAILED, payload: error });
    }
}

function* loadMoreModelsRequest(action) {
    try {
        let searchState = yield select(getSearchState);
        let request = {
            countryId: searchState.filter.country === "-1" ? 0 : searchState.filter.country,
            serviceId: searchState.filter.service === "-1" ? 0 : searchState.filter.service,
            brandId: searchState.filter.brand === "-1" ? 0 : searchState.filter.brand,
            categoryId: searchState.filter.category === "-1" ? 0 : searchState.filter.category,
            lastKey: searchState.models.data[searchState.models.data.length - 1].lastKey,
            count: 9,
            mode: 0
        }; 
        const response = yield call(getModels, request); 
        const fetchedModels = response;
        yield put({ type: FETCH_SEARCH_MODELS_SUCCESS, payload: { models: fetchedModels.models, totalCount: fetchedModels.totalCount } });


    } catch (error) {
        yield put({ type: FETCH_SEARCH_MODELS_FAILED, payload: error });
    }
}


function* changeParamSaga() {
    yield takeEvery([CHANGE_FILTER_PARAMS], changeParamHandler);
}

function* loadMoreModelsSaga() {
    yield takeEvery([FETCH_SEARCH_MODELS], loadMoreModelsRequest);
}



export default function* rootSaga() {
    yield all([fork(changeParamSaga)]);
    yield all([fork(loadMoreModelsSaga)]);
}