
import axios from '../utils/axiosInstance';

export function getEvetnsPage(request){  
     
    return axios.post('/api/Main/Events',request)
    .then(function (response) { 
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
} 


export function getEvent(id){  
    return axios.post('/api/Main/EventsById',{idEvent:id})
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
} 