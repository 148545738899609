import {
   FETCH_TODO_LIST,
   TODO_LIST_FETCHED_SUCCESSFULLY,
   TODO_LIST_FETCH_FAILED
} from '../actions/actions-types'


export const fetchTodoSuccess = (todoList) => {
    return {
        type: TODO_LIST_FETCHED_SUCCESSFULLY,
        payload: {data:todoList}
    }
};
export const fetchTodoFailed = (error) => {
    return {
        type: TODO_LIST_FETCH_FAILED,
        payload: {error}
    }
};

export const fetchTodo = () => {
    return {
        type: FETCH_TODO_LIST
    }
};
