import {
    FETCH_PROFILE,
    FETCH_PROFILE_FAILED,
    FETCH_PROFILE_SUCCESS,
    UPDATE_PROFILE,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE_FAILED
} from '../actions/actions-types';


const initialState = {
    loaded: false,
    loading: true,
    currentRequestList: [],
    comingRequestList: [],
    completedRequestList: [],
    profileInfo: {},
    totalRentals: {},
    statusCode: {
        code: 1,
        message: ""
    }

}


export default function profileReducer(state = initialState, action) {
    switch (action.type) {

        case FETCH_PROFILE: {

            return { ...state, loaded: false, loading: true }

        }
        case UPDATE_PROFILE: {

            return { ...state, loaded: false, loading: true }

        }

        case FETCH_PROFILE_SUCCESS: {
            return {
                ...state, loaded: true, loading: false,
                currentRequestList: action.payload.currentRequestList,
                comingRequestList: action.payload.comingRequestList,
                completedRequestList: action.payload.completedRequestList,
                profileInfo: action.payload.profile,
                totalRentals: action.payload.totalRentals,
                statusCode: {
                    code: 1,
                    message: ""
                }
            }
        }
        case UPDATE_PROFILE_FAILED: {
            return { ...state, loaded: false, loading: true }

        }
        case UPDATE_PROFILE_SUCCESS: {
            return {
                ...state, loaded: true, loading: false,
                statusCode: action.payload.statusCode
            }
        }
        case FETCH_PROFILE_FAILED: {
            return {
                ...state, loaded: false, loading: true,
                statusCode: {
                    code: 1,
                    message: ""
                }
            }

        }
        default:
            return state
    }
}