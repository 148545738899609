import Cookies from 'js-cookie'


export function authHeader() {
    // return authorization header with jwt token
    let user = JSON.parse(localStorage.getItem('user'));

    if (user && user.jwtToken) {
        // console.log(user.jwtToken)
        Cookies.set("jwt",user.jwtToken);
        // Cookies.set("refreshToken",user.refreshToken);
        //Cookies.set("refreshToken",user.refreshToken);
        return { 'Authorization': 'Bearer ' + user.jwtToken };
    } else {
        return {};
    }
}