import  axios from '../utils/axiosInstance';


export function getAgencies() {
 
    return axios.get('/api/Agency/AgencyList')
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        throw error;
      });

}