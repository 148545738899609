import React, { Component } from 'react';
import BrandsContainer from '../../containers/brands';
import FooterMenuContainer from '../../containers/footer'

class Footer extends Component {
    render() {
        return (
            <div>
               
               <BrandsContainer></BrandsContainer>
               <FooterMenuContainer></FooterMenuContainer>
            </div>
        );
    }
}

export default Footer;