import React, { Component } from 'react';
import { loadApp, loadFooter, loadFilter } from '../../redux/actions/app.actions';
import Routes from '../../routes';
import { connect } from 'react-redux';
import Footer from '../../components/Footer';
import { history } from '../../redux/store';
import { ConnectedRouter } from 'connected-react-router';
import ScrollToTop from '../../utils/components/scrollToTop';
import Header from '../../components/header';
import { ToastContainer } from 'react-toastify';



class AppContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirected: false
        }
    }


    componentWillMount() {
        // if (history.location.pathname === "/") {
        //     window.location.href = '/en';
        // }
        // else {
        //     this.setState({ redirected: true });
        // }
        this.setState({ redirected: true });
        this.props.dispatch(loadApp());
        this.props.dispatch(loadFooter());
        this.props.dispatch(loadFilter());
    }

    render() {
        return (
            <div>
                <ConnectedRouter history={history}>
                    <ScrollToTop >
                        <div>
                            {this.state.redirected && <div className="r-wrapper">
                                {this.props.appConfiguration.loaded &&
                                    <Header router={this.props.router}></Header>
                                }
                                
                                {this.props.appConfiguration.loaded &&
                                    <div className="content" style={{ minHeight: '100vh' }}>
                                        <Routes></Routes>
                                    </div>
                                }
                                {this.props.appConfiguration.loaded &&
                                    <Footer></Footer>
                                }
                            </div>}
                            <ToastContainer />
                        </div>
                    </ScrollToTop>
                    
                </ConnectedRouter>
            </div>
        );
    }
}

function mapPropsToState({ configuration, router }) {
    let { configuration: appConfiguration } = configuration;
    return { appConfiguration, router };
}
export default connect(mapPropsToState)(AppContainer);