import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import {
    FETCH_MAIN_NEWS,
    FETCH_MAIN_NEWS_SUCCESS,
    FETCH_MAIN_NEWS_FAILED,
    FETCH_NEWS_PAGE,
    FETCH_NEWS_PAGE_FAILED,
    FETCH_NEWS_PAGE_SUCCESS,
    FETCH_SINGLE_NEWS,
    FETCH_SINGLE_NEWS_SUCCESS,
    FETCH_SINGLE_NEWS_FAILED,
} from '../actions/actions-types';
import { getMainNews, getNews, getNewsPage } from '../../datasource/news.datasource';
import { fetchNews } from '../actions/news.actions';
//  import {  getNews,getNewsPage } from '../../datasource/news.datasource';


const getNewsState = (state) => state.news;

function* fetchMainNews() {
    try {
        const fetchedNews = yield call(getMainNews, { count: 3, lastKey: '' });
        yield put({ type: FETCH_MAIN_NEWS_SUCCESS, payload: { news: fetchedNews.news } });
    } catch (error) {

        yield put({ type: FETCH_MAIN_NEWS_FAILED, payload: { error } });
    }
}

function* fetchNewsPage(action) {

    try {
        let { paging } = yield select(getNewsState);
        let { lastKey } = paging;
        const fetchedNews = yield call(getNewsPage, { count: 10, lastKey });
        lastKey = fetchedNews.news[fetchedNews.news.length - 1].lastKey;

        yield put({ type: FETCH_NEWS_PAGE_SUCCESS, payload: { news: fetchedNews.news, lastKey, totalCount: fetchedNews.totalCount } });
    } catch (error) {
        yield put({ type: FETCH_NEWS_PAGE_FAILED, payload: { error } });
    }
}

function* fetchSingleNews(action) {
    try {
        let { id } = action.payload;
        const fetchedNews = yield call(getNews, id);
        yield put({ type: FETCH_SINGLE_NEWS_SUCCESS, payload: { news: fetchedNews.news } });
    } catch (error) {
        yield put({ type: FETCH_SINGLE_NEWS_FAILED, payload: { error } });
    }
}

function* fetchMainNewsSaga() {
    yield takeEvery(FETCH_MAIN_NEWS, fetchMainNews);
}

function* fetchPageNewsSaga() {
    yield takeEvery(FETCH_NEWS_PAGE, fetchNewsPage);
}

function* fetchSingleNewsSaga() {
    yield takeEvery(FETCH_SINGLE_NEWS, fetchSingleNews);
}
export default function* rootSaga() {
    yield all([fork(fetchMainNewsSaga)]);
    yield all([fork(fetchPageNewsSaga)]);
    yield all([fork(fetchSingleNewsSaga)]);
}