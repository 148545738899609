import axios from 'axios';
import {VAR_LANG_ID} from './settings';
import {authHeader} from '../helpers/auth-header'

// const loc = window.location.pathname;
// let elements = loc.split('/');
// let lang = elements.length > 1 && elements[1];


///// add specific language /////
let langId = VAR_LANG_ID;
// var B = authHeader();
// console.log(B)
///// add specific language /////


// switch (lang) {
//     case "en":
//         langId = 1;
//         break;
//     case "fr":
//         langId = 2;
//         break;
//         case "ru":
//         langId = 3;
//         break;
//     default:
//         langId = 1;
// }
const instance = axios.create({
    // baseURL: 'https://apidev.devapp.co/AAA/Website/'
    // baseURL: 'http://51.178.60.88:8083/WebsiteApi/'
    // baseURL: 'http://apidev.inmobiles.net/AAA/websiteseo/'
    //baseURL: 'https://apidev.devapp.co/AAA/Websiteseo/'
    //baseURL: 'http://portalapi.aaarentcars.com/Website'
    //baseURL: 'http://apidev.inmobiles.net/AAA/WebsiteJwt'
    //baseURL: 'https://apidev.devapp.co/AAA/WebsiteJwt'
    //baseURL: 'http://192.168.153.143:6161/WebsiteJwt/'
    // baseURL: 'http://portalapi.aaarentcars.com/Website/'
   baseURL: '/webapi/'
    //baseURL: 'http://portalapi.aaarentcars.com/Website'
    
    //baseURL: 'http://portalapi.aaarentcars.com/'


    // baseURL: 'https://api.test.inmobiles.net:4433/aaa/websiteapi/'
    //baseURL: 'https://api.test.inmobiles.net/AAA-WebsiteApi/'
    // baseURL: 'http://api.developmenthouse.net/AAA/WebsiteApi/'
});

instance.interceptors.request.use(request => { 
    return request
})
instance.interceptors.response.use(response => { 
    return response
})

// instance.defaults.withCredentials = true
// instance.defaults.crossDomain = true
instance.defaults.headers.common['idLanguage'] = langId;
instance.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
instance.defaults.headers.post['Access-Control-Allow-Methods'] = true;
instance.defaults.headers.post['Access-Control-Allow-Credentials'] = true;
// instance.defaults.headers.post['withCredentials'] = true;
// instance.defaults.headers.post['Access-Control-Allow-Headers'] = "x-requested-with,content-type";
instance.defaults.headers.common['Accept'] = "application/json";
//instance.defaults.headers.common = {'Authorization': `Bearer `}
//instance.defaults.headers.common = B;

export default instance;

