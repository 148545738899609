import { LOGIN_REQUEST,LOGOUT, SIGN_UP,FORGOT_PASSWORD ,CHANGE_PASSWORD} from './actions-types';

export function login(username, password,from,isBooking=false) {
    return { type: LOGIN_REQUEST, payload:{user:{username,password},from,isBooking} } ;
}

export function signUp(user,isBooking=false){
    return {type: SIGN_UP,payload:{user,isBooking}};
}

export function logout() {
    return { type: LOGOUT };
}

export function Forgot_Password(email){
    return {type: FORGOT_PASSWORD,payload:email};
}

export function Change_Password(idUser,oldPassword,newPassword,isReset,idUserEncrypted){
    return {type: CHANGE_PASSWORD,payload:{user:{idUser,oldPassword,newPassword,isReset,idUserEncrypted}}};
}

