
import axios from '../utils/axiosInstance';
import {GetToken} from '../redux/Recaptcha';
import {authHeader} from '../helpers/auth-header'
import Cookies from 'js-cookie';
import {sendRequestDotNet} from '../utils/axiosdotnet'

var Bearer = authHeader();

export function getUserProfile(request){ 
    return sendRequestDotNet('/api/User/UserProfile',request,{})
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}  

export function updateProfile(request){   
  return sendRequestDotNet('/api/User/UpdateProfile',request,{
    headers: Bearer
  })
  .then(function (response) { 
    return response.data;
  })
  .catch(function (error) {
    throw error;
  });
}  

export function changePassword(request){  
     
  return GetToken('changePassword').then(function (token) {
    const headers = {
      'recaptcha': token,
      Bearer
    }  
    return sendRequestDotNet('/api/User/ChangePassword',request,{
      headers: headers
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
  });
}  


