import React, { Component } from 'react';
import { connect } from 'react-redux';
import Brands from '../../components/brands';

class BrandsContainer extends Component {
    render() {

        return (
            <div>
                <Brands items={this.props.configurationData.data.brands} >
                </Brands>
            </div>

        );
    }
}

function mapStateToProps({ configuration }) {
    let { configuration: configurationData } = configuration;
    return { configurationData };
}

export default connect(mapStateToProps)(BrandsContainer);