
import axios from '../utils/axiosInstance';
import { sendRequestDotNet } from '../utils/axiosdotnet';

export function getNewsPage(request){  
     
    return axios.post('/api/News/GetNews',request)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
} 


export function getNews(id){  
    return axios.post('/api/News/GetNewsById',{idNews:id})
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
} 


export function getMainNews(request){  
  request={

     count: 3,
     lastKey: ''
   }
 return axios.post('/api/News/GetNews',request)
 .then(function (response) {
   return response.data;
 })
 .catch(function (error) {
   throw error;
 });
} 
