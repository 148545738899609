import axios from '../utils/axiosInstance';

export function getModels(request) { 
    return axios.post('/api/Vehicle/VehicleList', request) 
        .then(function (response) {  
            return response.data;
        })
        .catch(function (error) {
            throw error;
        });
}
 
export function getModelDetails(request) {
    let idCountry = (request.country) ? request.country : 0;
    let idService = 0;

    request = {
        idvehicle: request.id,
        idService: idService,
        idCountry: idCountry
    };
    return axios.post('/api/VehicleDetails/GetVehicleDetails', request)
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            throw error;
        });
}


export function getModelServiceDetails(request) {
    return axios.post('/api/VehicleDetails/GetVehiclePackage', request)
        .then(function (response) {
            
            response.data.similarModels = [
                {
                    idVehicle: "1",
                    name: "model 7",
                    price: "45.00 USD",
                    imageUrl: "http://aaaimages.developmenthouse.net/images/cars/1.png",
                    modelFeature: "",
                    isComingSoon: ""
                },
                {
                    idVehicle: "2",
                    name: "model 2",
                    price: "45.00 USD",
                    imageUrl: "http://aaaimages.developmenthouse.net/images/cars/2.png",
                    modelFeature: "",
                    isComingSoon: ""
                },
                {
                    idVehicle: "3",
                    name: "model 3",
                    price: "45.00 USD",
                    imageUrl: "http://aaaimages.developmenthouse.net/images/cars/3.png",
                    modelFeature: "",
                    isComingSoon: ""
                },
                {
                    idVehicle: "4",
                    name: "model 4",
                    price: "45.00 USD",
                    imageUrl: "http://aaaimages.developmenthouse.net/images/cars/4.png",
                    modelFeature: "",
                    isComingSoon: ""
                },
                {
                    idVehicle: "5",
                    name: "model 5",
                    price: "45.00 USD",
                    imageUrl: "http://aaaimages.developmenthouse.net/images/cars/5.png",
                    modelFeature: "",
                    isComingSoon: ""
                },
                {
                    idVehicle: "6",
                    name: "model 6",
                    price: "45.00 USD",
                    imageUrl: "http://aaaimages.developmenthouse.net/images/cars/6.png",
                    modelFeature: "",
                    isComingSoon: ""
                }
            ]

            return response.data;
        })
        .catch(function (error) {
            throw error;
        });
}


export function getServicesByModel(request) {
    return new Promise(function (resolve, reject) {
        setTimeout(function () {
            resolve({
                result: {
                    code: 0,
                    description: "success"
                },
                services: [
                    {
                        id: "1"
                    }
                ]
            });
        }, 300);
    });
}


export function getMonthlyModelSelections(request) { 
    request = {
        countryId: 0,
        serviceId: 0,
        brandId: 0,
        categoryId: 0,
        lastKey: '',
        count: 6,
        mode: 1
    } 
    return axios.post('/api/Vehicle/VehicleList', request)
  
        .then(function (response) { 
            return response.data;
        })
        .catch(function (error) {
            throw error;
        });
}