import {
    FETCH_PRICING_DETAILS,
    FETCH_PRICING_DETAILS_SUCCESS,
    FETCH_PRICING_DETAILS_FAILED
} from '../actions/actions-types';


const initialState = {
    modelId:"",
    serviceId:"",
    countryId:"",
    pricing: {
        loaded: false,
        loading: false,
        totalPrice: 0,
        discountedPrice:0,
        service:"",
        extraServices: []
    },
  

}


export default function newsReducer(state = initialState, action) {
    switch (action.type) {

        case FETCH_PRICING_DETAILS: {
            return { ...state, pricing: { ...state.pricing, loaded: false, loading: true, extraServices: [], totalPrice: 0 } }
        }


        case FETCH_PRICING_DETAILS_SUCCESS: {
            return { ...state, pricing: { ...state.pricing, loaded: true, loading: false, extraServices: action.payload.extraServices, totalPrice: action.payload.pricing.price,service:action.payload.pricing.service,discountedPrice:action.payload.pricing.discountedPrice } }
        }


        case FETCH_PRICING_DETAILS_FAILED: {
            return { ...state, pricing: { ...state.pricing, loaded: false, loading: false } }
        }

  

        default:
            return state
    }
}