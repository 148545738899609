import axios from '../utils/axiosInstance';


export function getSliderData() {
  return axios.get('/api/Main/Slider')
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

export function getConfigurationData() {
  return axios.get('/api/Main/Configuration')
    .then(function (response) { 
      let configuration = response.data.configuration;
      configuration.services = configuration.services.map(service => {
        const { idService, metaSlug, serviceName, ...otherProps } = service;
        return { id: metaSlug, name: serviceName, ...otherProps };
      });
      configuration.brands = configuration.brands.map(brand => {
        const { idBrand, metaSlug, brandName, ...otherProps } = brand;
        return { id: metaSlug, name: brandName, ...otherProps };
      });
      configuration.countries = configuration.countries.map(country => {
        const { idCountry, metaSlug, countryName, ...otherProps } = country;
        return { id: metaSlug, name: countryName, ...otherProps };
      });
      configuration.categories = configuration.categories.map(category => {
        const { idCategory, metaSlug, categoryName, ...otherProps } = category;
        return { id: metaSlug, name: categoryName, ...otherProps };
      })
      configuration.cities = configuration.cities.map(city => {
        const { idCity, metaSLug, ...otherProps } = city;
        return { idCity: metaSLug, ...otherProps };
      })
      configuration.eventCities = configuration.eventCities.map(city => {
        const { idEventCity, metaSlug, ...otherProps } = city;
        return { idEventCity: metaSlug, ...otherProps };
      })
      configuration.events = configuration.events.map(event => {
        const { idEvent, metaSlug, ...otherProps } = event;
        return { idEvent: metaSlug, ...otherProps };
      })
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

export function getFooterMenu() {
  return axios.get('/api/Main/Footer')
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}


export function getFilterMetaData() {
  return axios.get('/api/Main/AvailableCombination')
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

export function getLocations(request) {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      resolve({
        result: {
          code: 0,
          description: "success"
        },
        locations: [
          {
            id: "1",
            name: "Beirut"
          },
          {
            id: "2",
            name: "Saida"
          },
          {
            id: "3",
            name: "Hamra"
          },
          {
            id: "4",
            name: "Achrafieh"
          },
          {
            id: "5",
            name: "Sassin"
          },
          {
            id: "6",
            name: "Jounieh"
          },
        ]

      });
    }, 300);
  });
}