import { all, call, select, fork, put, takeEvery } from 'redux-saga/effects';
import {
    FETCH_MODEL_DETAILS,
    FETCH_MODEL_DETAILS_SUCCESSFULLY,
    FETCH_MODEL_DETAILS_FAILED,
    FETCH_MODEL_SERVICE_DETAILS_SUCCESSFULLY,
    FETCH_MODEL_SERVICE_DETAILS_FAILED,
    CHANGE_MODEL_FILTER_PARAMS
} from '../actions/actions-types';
import { getModelDetails, getModelServiceDetails } from '../../datasource/model.datasource';
import {VAR_LANG_ID,CAR_RENTAL,CHAUFFEUR_SERVICES,TRANSFER_SERVICES} from '../../utils/settings';

const getModelState = (state) => state.model;
const getConfigurationState = (state) => state.configuration;

function* fetchModelDetailsRequest(action) {
    try {
        let modelState = yield select(getModelState);
        //fetch model details
        // if (modelState.modelId !== action.payload.modelId) {
            const response = yield call(getModelDetails,{id:action.payload.modelId, country: action.payload.country});
            let idService = CAR_RENTAL;
            if(response.vehicleDetails && response.vehicleDetails !== null && response.vehicleDetails.idService)
                idService = response.vehicleDetails.idService;

            let country = action.payload.country;
            let filters = {
                brand: "-1",
                category: "-1",
                country: (country !== "") ? country : "-1",
                service: idService
            }
            yield put({ type: FETCH_MODEL_DETAILS_SUCCESSFULLY, payload: { filters: filters, modelId: action.payload.modelId, details: response.vehicleDetails, cities: response.cities,availableServices:response.availableServices,availableVehicleService:response.availableVehicleService,availableCountries:response.availableCountries,availableCities:response.availableCities } });
        // }

    } catch (error) {
        console.error(error);
        yield put({ type: FETCH_MODEL_DETAILS_FAILED, payload: error });
    }
}

function* changeModelService() {
    try {
        let modelState = yield select(getModelState);
        

        let dataSend = {idvehicle:modelState.modelId};
        dataSend['idCountry'] = (modelState.filter.country === undefined) ? "" : modelState.filter.country;
        let response = yield call(getModelServiceDetails,dataSend);

        yield put({ type: FETCH_MODEL_SERVICE_DETAILS_SUCCESSFULLY, payload: { vehicleRates:response.vehicleRates,vehicleGallery:response.vehicleGallery,vehicleDescription:response.vehicleDescription,similarModels:response.similarModels } });
    } catch (error) {
        console.error(error);
        yield put({ type: FETCH_MODEL_SERVICE_DETAILS_FAILED, payload: error });
    }
}


function* fetchModelDetailsSaga() {
    yield takeEvery(FETCH_MODEL_DETAILS, fetchModelDetailsRequest);
}

function* changeModelServiceSaga() {
    yield takeEvery(CHANGE_MODEL_FILTER_PARAMS, changeModelService);
}

export default function* rootSaga() {
    yield all([fork(fetchModelDetailsSaga)]);
    yield all([fork(changeModelServiceSaga)]);
}