import {
    FETCH_EVENTS_PAGE,
    FETCH_EVENT,
    FETCH_EVENTS_PAGE_SUCCESS,
    FETCH_EVENT_SUCCESS,
    FETCH_EVENTS_PAGE_FAILED,
    FETCH_EVENT_FAILED,
    APPLY_CHANGE_FILTER_PARAMS
} from '../actions/actions-types';


const initialState = {

    filter: {
        city: "-1",
        event: "-1" 
    },
    models: {
        totalCount:0,
        loading: false,
        loaded: false,
        data: []
    },
    openedEvent: {
        loaded: false,
        loading: false,
        details: null
    }
}


export default function eventsReducer(state = initialState, action) {
    switch (action.type) {
       
        case APPLY_CHANGE_FILTER_PARAMS: {
            return {
                ...state, filter: { ...state.filter, ...action.payload },models: { ...state.models, loaded: false, loading: true ,data:[]}
            }
        }

        case FETCH_EVENT: {
          
            return { ...state, openedEvent: { ...state.openedEvent, loaded: false, loading: true, details: null} }
        
        } 
        case FETCH_EVENT_SUCCESS: {
            return { ...state, openedEvent: { ...state.openedEvent, loaded: true, loading: false, details: action.payload.event} }
        }


        case FETCH_EVENT_FAILED: {
            return { ...state, openedEvent: { ...state.openedEvent, loaded: false, loading: false} }

        }

        case FETCH_EVENTS_PAGE: {
            return {
                ...state, models: { ...state.models, loaded: false, loading: true }
            }
           
        }


        case FETCH_EVENTS_PAGE_SUCCESS: {
          
            return {
                ...state, models: { ...state.models, loaded: true, loading: false,totalCount:action.payload.totalCount, data: state.models.data.concat(action.payload.models) }
            }
        }


        case FETCH_EVENTS_PAGE_FAILED: {
            return {
                ...state, models: { ...state.models, loaded: true, loading: false }
            }
        }

        default:
            return state
    }
}