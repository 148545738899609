import { LOAD_APP, LOAD_FOOTER, LOAD_FILTER } from "./actions-types";




export function loadApp() {
    return { type: LOAD_APP } ;
}
export function loadFooter() {
    return { type: LOAD_FOOTER } ;
}
export function loadFilter() {
    return { type: LOAD_FILTER } ;
}
