
import {
  
    FETCH_MODEL_DETAILS,
    FETCH_MODEL_DETAILS_SUCCESSFULLY,
    FETCH_MODEL_DETAILS_FAILED,
    CHANGE_MODEL_SERVICE,
    CHANGE_MODEL_FILTER_PARAMS,
    FETCH_MODEL_SERVICE_DETAILS_SUCCESSFULLY,
    FETCH_MODEL_SERVICE_DETAILS_FAILED
} from '../actions/actions-types'

const INIT_STATE = {
    modelId: '',
    filter: {
        service: '-1',
        country: '-1'
    },
    modelDetails: {
        loaded: false,
        loading: false,
        availableServices:[],
        availableCountries:[],
        availableVehicleService:[],
        availableCities:[],
        cities:[],
        details: {
        }
    },
    servicesDetails:
    {
        loading: false,
        loaded: false,
        similarModels: [],
        rates: [],
        vehicleGallery: [],
        vehicleDescription: {}

    }

};

export default (state = INIT_STATE, action) => {
    switch (action.type) {


        case FETCH_MODEL_DETAILS: {

            // var act= action;
            // debugger;
            return {
                ...state, modelId: action.payload.modelId, modelDetails: { ...state.modelDetails, loaded: false}
            }

        }



        case FETCH_MODEL_DETAILS_SUCCESSFULLY: {
            return {
                ...state, 
                filter: action.payload.filters, modelId: action.payload.modelId, 
                modelDetails: { ...state.modelDetails, 
                    loaded: true, loading: false,
                     details: action.payload.details,
                     availableServices:action.payload.availableServices,
                     availableVehicleService:action.payload.availableVehicleService,
                     availableCountries:action.payload.availableCountries,
                     availableCities:action.payload.availableCities,
                      cities: action.payload.cities }
            }

        }
        case FETCH_MODEL_DETAILS_FAILED: {
            return {
                ...state, modelDetails: { ...state.modelDetails, loaded: true, loading: false }
            }

        }

      

        case CHANGE_MODEL_FILTER_PARAMS: {// filter: { ...state.filter, ...action.payload },
            return {
                ...state,
                servicesDetails: { ...state.servicesDetails, loaded: false, loading: true, carId: '', similarModels: [], rates: [] }
            }
        }

        case CHANGE_MODEL_SERVICE: {

            return {
                ...state, servicesDetails: { ...state.servicesDetails, loaded: false, loading: true, carId: '', similarModels: [], rates: [] }, filter: { ...state.filter, service: action.payload.serviceId }
            }

        }
        case FETCH_MODEL_SERVICE_DETAILS_SUCCESSFULLY: {
            return {
                ...state, servicesDetails: { ...state.servicesDetails, loaded: true, loading: false, similarModels: action.payload.similarModels, rates: action.payload.vehicleRates, vehicleGallery: action.payload.vehicleGallery, vehicleDescription: action.payload.vehicleDescription }
            }

        }
        case FETCH_MODEL_SERVICE_DETAILS_FAILED: {
            return {
                ...state, servicesDetails: { ...state.servicesDetails, loaded: true, loading: false }
            }

        }
        default:
            return state;
    }
}