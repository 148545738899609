import {
    FETCH_MAIN_NEWS,
    FETCH_NEWS_PAGE,
    FETCH_SINGLE_NEWS,
    FETCH_MAIN_NEWS_SUCCESS,
    FETCH_NEWS_PAGE_SUCCESS,
    FETCH_SINGLE_NEWS_SUCCESS,
    FETCH_MAIN_NEWS_FAILED,
    FETCH_NEWS_PAGE_FAILED,
    FETCH_SINGLE_NEWS_FAILED
} from '../actions/actions-types';


const initialState = {

    mainNews: {
        loaded: false,
        loading: false,
        list: []
    },
    paging: {
        lastKey:'',
        totalCount:0,
        loaded: false,
        loading: false,
        list: []
    },
    openedNews: {
        loaded: false,
        loading: false,
        details: null
    }
}


export default function newsReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_MAIN_NEWS:
            {
                return { ...state, mainNews: { ...state.mainNews, loaded: false, loading: true, list: []} }
            }
        case FETCH_MAIN_NEWS_SUCCESS: {
            return { ...state, mainNews: { ...state.mainNews, loaded: true, loading: false, list: action.payload.news } }
           

        }


        case FETCH_MAIN_NEWS_FAILED: {
            return { ...state, mainNews: { ...state.mainNews, loaded: true, loading: false } }

           
        }
        case FETCH_SINGLE_NEWS: {
          
            return { ...state, openedNews: { ...state.openedNews, loaded: false, loading: true, details: null} }
        
        }


        case FETCH_SINGLE_NEWS_SUCCESS: {
            return { ...state, openedNews: { ...state.openedNews, loaded: true, loading: false, details: action.payload.news} }
        }


        case FETCH_SINGLE_NEWS_FAILED: {
            return { ...state, openedNews: { ...state.openedNews, loaded: false, loading: false} }

        }

        case FETCH_NEWS_PAGE: {
            return { ...state, paging: { ...state.paging,  loading: true} }
           
        }


        case FETCH_NEWS_PAGE_SUCCESS: {
           
            return { ...state, paging: { ...state.paging, loaded: true, loading: false,totalCount:action.payload.totalCount,lastKey:action.payload.lastKey, list: state.paging.list.concat (action.payload.news)} }

        }


        case FETCH_NEWS_PAGE_FAILED: {
            return { ...state, paging: { ...state.paging, loaded: false, loading: false} }
        }

        default:
            return state
    }
}