import { SIGN_UP, SIGN_UP_SUCCESS,SIGN_UP_FAILURE } from '../actions/actions-types';

// let user = JSON.parse(localStorage.getItem('user'));
const INITIAL_STATE = {
    loading: false,
    loaded: false,
    success:false,
    message:''
} 
export default function authentication(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SIGN_UP:

            return {
                ...state,
                loading: true,
                loaded: false
            };
        case SIGN_UP_SUCCESS:

            return {
                ...state,
                loading: false,
                loaded: true,
                success:true
            }; 
            case SIGN_UP_FAILURE:

            return {
                ...state,
                loading: false,
                loaded: true,
                success:false,
                message:action.payload.message
            };
       
     
        default:
            return state
    }
}