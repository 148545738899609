
import {
    // CHANGE_FILTER_PARAMS,
    APPLY_CHANGE_FILTER_PARAMS,
    FETCH_SEARCH_MODELS,
    FETCH_SEARCH_MODELS_SUCCESS,
    FETCH_SEARCH_MODELS_FAILED,
} from '../actions/actions-types'

const INIT_STATE = {
    filter: {
        country: "-1",
        service: "-1",
        brand: "-1",
        category:"-1"
    },
    categories: {
        loading: false,
        loaded: false,
        data: []
    },
    models: {
        totalCount:0,
        loading: false,
        loaded: false,
        data: []
    }
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
    

        case APPLY_CHANGE_FILTER_PARAMS: {
            return {
                ...state, filter: { ...state.filter, ...action.payload },models: { ...state.models, loaded: false, loading: true ,data:[]}
            }
        }


        case FETCH_SEARCH_MODELS: {
            return {
                ...state, models: { ...state.models, loaded: false, loading: true }
            }
        }

        case FETCH_SEARCH_MODELS_SUCCESS: {
            return {
                ...state, models: { ...state.models, loaded: true, loading: false,totalCount:action.payload.totalCount, data: state.models.data.concat(action.payload.models) }
            }
        }

        case FETCH_SEARCH_MODELS_FAILED: {
            return {
                ...state, models: { ...state.models, loaded: true, loading: false }
            }
        }
        default:
            return state;
    }
}