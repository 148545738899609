//English
// export const VAR_LANG_ID =1;

// export const CAR_RENTAL ="car-rental";
// export const CHAUFFEUR_SERVICES ="chauffeur-services";
// export const TRANSFER_SERVICES ="transfer-services";
// export const RENT_ROUTE ="rent";
// export const RATES_ROUTE ="rates";
// export const NEWS_ROUTE ="news";
// export const EVENT_ROUTE ="events-list";
// export const BRAND_ROUTE ="brand";
// export const SIGNUP_ROUTE ="signup";
// export const BOOKING_ROUTE ="booking";
// export const BOOK_ROUTE ="book";
// export const LOGIN_ROUTE ="login";
// export const PROFILE_ROUTE ="profile";

// export const ALL_CAT ="all-categories";

// ///French
import 'moment/locale/fr';
export const VAR_LANG_ID =2;

export const CAR_RENTAL ="location-de-voitures";
export const CHAUFFEUR_SERVICES ="location-voiture-avec-chauffeur";
export const TRANSFER_SERVICES ="service-transfert";
export const RENT_ROUTE ="location";
export const RATES_ROUTE ="prix";
export const NEWS_ROUTE ="actualites";
export const EVENT_ROUTE ="liste-evenement";
export const BRAND_ROUTE ="marque";
export const SIGNUP_ROUTE ="inscription";
export const BOOKING_ROUTE ="reservation";
export const BOOK_ROUTE ="réserver";
export const LOGIN_ROUTE ="connexion";
export const PROFILE_ROUTE ="profil";

export const ALL_CAT ="toutes-categories";





////Russia
// import 'moment/locale/ru';
// export const VAR_LANG_ID =3;

// export const CAR_RENTAL ="car-rental";
// export const CHAUFFEUR_SERVICES ="chauffeur-services";
// export const TRANSFER_SERVICES ="transfer-services";
