import React, { Component } from 'react'; 
class FooterMenu extends Component {
    render() { 
        return (
            <footer>
                <div className="f-footer pt-0">
                    <div className="f-top-footer">
                        <div className="container">
                            <div className="row">

                                {this.props.items.map((item, index) =>
                                    <div key={index} className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-6">
                                        <h5 className="yellow-underline">{item.menuTitle}</h5>
                                        <nav>
                                            <ul>
                                                {item.subMenus.map((submenu, index) =>
                                                    <li key={index}><a target={submenu.isNewWindow?"_blank":"_self" } href={submenu.subMenuLink}>{submenu.subMenuTitle}</a></li>)}
                                            </ul>
                                        </nav>
                                    </div>)
                                }
                            </div>
                        </div>
                    </div>
                    <div className="f-footer-strip">
                        <div className="container">
                            <div className="row clearfix">
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                    <p>AAA Luxury & Sport Cars Rental ©2021 | Privacy Policy</p>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                    <div className="media-icons pull-right">
                                        <a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/aaa.luxury.sport.cars.rental"><i className="icon-fb"></i></a>
                                        <a rel="noopener noreferrer" target="_blank" href="https://plus.google.com/112432202757578439230/posts"><i className="icon-g"></i></a>
                                        <a rel="noopener noreferrer" target="_blank" href="https://instagram.com/aaarentcars?utm_medium=copy_link"> <i className="icon-Insta"></i></a>
                                        <a rel="noopener noreferrer" target="_blank" href="https://www.youtube.com/user/3ALuxury"> <i className="icon-youtube"></i></a>
                                    </div>
                                    {/* <p className="r-copy-right-text">©2019 by<strong> InMobiles.com</strong></p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <a href="#header" className="r-scroll-up-icon"> <i className="fa fa-angle-double-up"></i> </a> */}
            </footer>
        );
    }
}

export default FooterMenu;