import React, { Component } from 'react';
import {history} from '../../redux/store';
// import { matchPath } from "react-router";
import {BRAND_ROUTE} from '../../utils/settings';

// const match = matchPath(
//     history.location.pathname,
//     { path: '/:lang' }
// );
class Brands extends Component { 
      handleBrandClick=(event)=>{
            // history.push(`/search?brand=${event.target.id}`);
            history.push(`/${BRAND_ROUTE}/${event.target.id}`);
        }
    render() {
     
        return (
            <section id="r-car-brands">
                <div className="clearfix text-center r-brand-items">

                    {this.props.items.map(brand =>
                        <div key={brand.id}  className="brand-item">
                            <img  src={brand.imageUrl} id={brand.id} onClick={this.handleBrandClick} alt=" car brand" />
                        </div>)
                    }
                </div>
            </section>
        );
    }
}

export default Brands;