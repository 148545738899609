import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import {
    FETCH_AGENCIES,
    FETCH_AGENCIES_FAILED,
    FETCH_AGENCIES_SUCCESS,

} from '../actions/actions-types';
import { getAgencies } from '../../datasource/agency.datasource'

const getAgenciesState = (state) => state.agencies;



function* fetchAgencies() {
    try {
        let agenciesState = yield select(getAgenciesState);
        if (!agenciesState.agencies.loaded) {
            const response = yield call(getAgencies);
            yield put({ type: FETCH_AGENCIES_SUCCESS, payload: { agencies: response.agencies } });
        }

    } catch (error) {
        yield put({ type: FETCH_AGENCIES_FAILED, payload: { error } });
    }
}





function* fetchAgenciesSaga() {
    yield takeEvery(FETCH_AGENCIES, fetchAgencies);
}


export default function* rootSaga() {
    yield all([fork(fetchAgenciesSaga)]);
}