import Cookies from 'js-cookie';
import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT,FORGOT_PASSWORD,FORGOT_PASSWORD_FAILURE,FORGOT_PASSWORD_SUCCESS ,CHANGE_PASSWORD,CHANGE_PASSWORD_SUCCESS,CHANGE_PASSWORD_FAILURE} from '../actions/actions-types';

let user = JSON.parse(localStorage.getItem('user')); 
const initialState = user ? 
{ loggedIn: true, loading: true, success: false, statusCode: {}, user } : 
{ loading: false, loggedIn: false, success: false,user };  
export default function authentication(state = initialState, action) {
    switch (action.type) {
        case LOGIN_REQUEST:
            return {
                ...state,
                loading: true,
                loggedIn: false,
                loaded:false,
                user: action.payload.user,
                from: action.payload.from,
                isBooking:action.payload.isBooking
            };
            case FORGOT_PASSWORD: 
            return {
                ...state,
                loading: true, 
                loaded:false ,
                success:false
                
            };
            case FORGOT_PASSWORD_SUCCESS: 
                return {
                    ...state,
                    loading: false, 
                    loaded:true ,
                    success:true,
                    statusCode:action.payload.statusCode
                };
         
            case FORGOT_PASSWORD_FAILURE:
                return { ...state,loaded:true,loading:false,   success:false};
        case LOGIN_SUCCESS:
            
            localStorage.setItem('user', JSON.stringify(action.payload));
            
            return {
                ...state,
                loading: false,
                loggedIn: true,
                loaded:true,
                user: action.payload 
            };
     
        case LOGIN_FAILURE:
            return { ...state,loggedIn:false ,loaded:true,loading:false};
        case LOGOUT:
            Cookies.remove('jwt');
            localStorage.removeItem('user');
            return {
                loggedIn: false,
                user: {},
                from: '/'
            };
            case CHANGE_PASSWORD: 
            return {
                ...state,
                // loading: true, 
                // loaded:false ,
                success:true
                
            };
            case CHANGE_PASSWORD_SUCCESS: 
                return {
                    ...state,
                    loading: false, 
                    loaded:true ,
                    success:true
                };
         
            case CHANGE_PASSWORD_FAILURE:
                return { ...state,loaded:true,loading:false,   success:false};
        default:
            return state
    }
}