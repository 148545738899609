import todoReducer from './todo.reducer'
import authReducer from './auth.reducer'
import signUpReducer from './signUp.reducer'
import newsReducer from './news.reducer'
import eventsReducer from './events.reducer'
import agenciesReducer from './agencies.reducer'
import configurationReducer from './configuration.reducer'
import searchReducer from './search.reducer'
import modelReducer from './model.reducer'
import monthlySelectionsReducer from './monthlySelections.reducer'
import bookingReducer from './booking.reducer'
import {  intlReducer } from 'react-intl-redux'
import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux';
import profileReducer from './profile.reducer'



 export default (history) => combineReducers({
    todo:todoReducer,
    intl:intlReducer,
    authentication:authReducer,
    signUp:signUpReducer,
    news:newsReducer,
    events:eventsReducer,
    agencies:agenciesReducer,
    search:searchReducer,
    model:modelReducer,
    userProfile:profileReducer,
    monthlySelections:monthlySelectionsReducer,
    configuration:configurationReducer,
    booking:bookingReducer,
    router: connectRouter(history),
  })

