import {
    FETCH_CONFIGURATION,
    FETCH_FOOTER_MENU,
    FETCH_SLIDER_DATA,
    FETCH_FILTER_META_DATA,
    // FETCH_LOCATIONS
} from "./actions-types";


export function fetchFooterMenu() {
    return { type: FETCH_FOOTER_MENU };
}

export function fetchConfiguration() {
    return { type: FETCH_CONFIGURATION };
}

export function fetchSliderData() {
    return { type: FETCH_SLIDER_DATA };
}

export function fetchFilterMetaData() {
    return { type: FETCH_FILTER_META_DATA };
}

// export function fetchLocations() {
//     return { type: FETCH_LOCATIONS };
// }