import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import {
    FETCH_EVENTS_PAGE,
    FETCH_EVENT,
    FETCH_EVENTS_PAGE_SUCCESS,
    FETCH_EVENT_SUCCESS,
    FETCH_EVENTS_PAGE_FAILED,
    FETCH_EVENT_FAILED, 
    APPLY_CHANGE_FILTER_PARAMS,
    FETCH_SEARCH_MODELS,
    CHANGE_FILTER_PARAMS
} from '../actions/actions-types';
import {  getEvent, getEvetnsPage } from '../../datasource/events.datasource';
import _ from 'lodash';

const getEventsState = (state) => state.events;
const getSearchState = (state) => state.search;

function* fetchEventsPage(action) {
    try {
        
        let { paging } = yield select(getEventsState);
        let { lastKey } = paging;
        const fetchedEvents = yield call(getEvetnsPage, { count: 10, lastKey });
        lastKey = fetchedEvents.events[fetchedEvents.events.length - 1].lastKey;

        yield put({ type: FETCH_EVENTS_PAGE_SUCCESS, payload: { events: fetchedEvents.events, lastKey, totalCount: fetchedEvents.totalCount } });
    } catch (error) {
        yield put({ type: FETCH_EVENTS_PAGE_FAILED, payload: { error } });
    }
}

function* fetchEvent(action) {
    try {
        let { id } = action.payload;
        const fetchedEvent = yield call(getEvent, id);
        yield put({ type: FETCH_EVENT_SUCCESS, payload: { event: fetchedEvent.event } });
    } catch (error) {
        yield put({ type: FETCH_EVENT_FAILED, payload: { error } });
    }
}

function* changeParamHandler(action) {
    try {
      
        let request = {
            City: action.payload.city === "-1" ? 0 : action.payload.city,
            Event: action.payload.event === "-1" ? 0 : action.payload.event, 
            lastKey: '',
            count: 9 
        };
        let searchState = yield select(getSearchState);
        if (!_.isEqual(searchState.filter, action.payload) || !searchState.models.loaded) {
            yield put({ type: APPLY_CHANGE_FILTER_PARAMS, payload: action.payload });
            //console.log('changeParamHandler.request',request)
            const response = yield call(getEvetnsPage, request);
            const fetchedModels = response;   
            yield put({ type: FETCH_EVENTS_PAGE_SUCCESS, payload: { models: fetchedModels.events, totalCount: fetchedModels.totalCount } });
        }

    } catch (error) {

        yield put({ type: FETCH_EVENTS_PAGE_FAILED, payload: error });
    }
}
function* loadMoreModelsRequest(action) {
    try {
        let searchState = yield select(getSearchState);
        let request = {
            City: searchState.filter.city === "-1" ? 0 : searchState.filter.city,
            Event: searchState.filter.event === "-1" ? 0 : searchState.filter.event, 
            lastKey: searchState.models.data[searchState.models.data.length - 1].lastKey,
            count: 9
        }; 
        const response = yield call(getEvetnsPage, request); 
        const fetchedModels = response;
        yield put({ type: FETCH_EVENTS_PAGE_SUCCESS, payload: { models: fetchedModels.events, totalCount: fetchedModels.totalCount } });


    } catch (error) {
        yield put({ type: FETCH_EVENTS_PAGE_FAILED, payload: error });
    }
}
function* fetchPageEventsSaga() {
    yield takeEvery(FETCH_EVENTS_PAGE, fetchEventsPage);
}
function* loadMoreModelsSaga() {
    yield takeEvery([FETCH_SEARCH_MODELS], loadMoreModelsRequest);
}
function* changeParamSaga() {
    yield takeEvery([CHANGE_FILTER_PARAMS], changeParamHandler);
}
function* fetchEventSaga() {
    yield takeEvery(FETCH_EVENT, fetchEvent);
}
export default function* rootSaga() {
    yield all([fork(fetchPageEventsSaga)]);
    yield all([fork(fetchEventSaga)]);
    yield all([fork(changeParamSaga)]);
    yield all([fork(loadMoreModelsSaga)]);


}