import { all, call, fork, put, takeEvery ,select} from 'redux-saga/effects';
import {
    FETCH_MONTHLY_MODELS,
    FETCH_MONTHLY_MODELS_SUCCESSFULLY,
    FETCH_MONTHLY_MODELS_FAILED,


} from '../actions/actions-types';
import { getMonthlyModelSelections } from '../../datasource/model.datasource'


const getMonthlySelectionsState = (state) => state.monthlySelections;

function* fetchMonthlySelectionsPage(action) {
    try { 
        let monthlySelectionsState = yield select(getMonthlySelectionsState);
        if (!monthlySelectionsState.models.loaded) {
            let { categoryId, brandId, serviceId, countryId } = action.payload;
            const response = yield call(getMonthlyModelSelections, { categoryId, brandId, serviceId, countryId }); 
            yield put({ type: FETCH_MONTHLY_MODELS_SUCCESSFULLY, payload: { models: response.models } });
        }

    } catch (error) {
        console.error(error);
        yield put({ type: FETCH_MONTHLY_MODELS_FAILED, payload: { error } });
    }
}

function* fetchPageMonthlySelectionsSaga() {
    yield takeEvery(FETCH_MONTHLY_MODELS, fetchMonthlySelectionsPage);
}

export default function* rootSaga() {
    yield all([fork(fetchPageMonthlySelectionsSaga)]);
}