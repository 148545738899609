import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom'
import Loadable from 'react-loadable'
// import Authentication from '../containers/Authentication'
import loaderGif from '../assests/images/loader.gif'
// import { TransitionGroup, CSSTransition } from "react-transition-group-v2";
import store, { history } from '../redux/store'
// import { matchPath } from 'react-router-dom';
import { updateIntl } from 'react-intl-redux'
import messages_ar from '../text/ar.json'
import messages_en from '../text/en.json'
import messages_fr from '../text/fr.json'
import messages_ru from '../text/ru.json'

import {VAR_LANG_ID,RENT_ROUTE,PROFILE_ROUTE,RATES_ROUTE, LOGIN_ROUTE,NEWS_ROUTE,EVENT_ROUTE, SIGNUP_ROUTE,BOOKING_ROUTE,BOOK_ROUTE} from '../utils/settings';

const messages = {
  'ar': messages_ar,
  'en': messages_en,
  'fr': messages_fr,
  'ru': messages_ru
};

function Loading() {
  return <div style={{ margin: "auto", position: 'absolute', transform: 'translate(-50%,-50%)', top: '50%', left: '50%' }}>
    <img style={{ width: "50px", display: 'block', margin: 'auto' }} src={loaderGif} alt="loading..." />
  </div>
}


const LoginPage = Loadable({
  loader: () => import('./login'),
  loading: Loading,
});

const SignUp = Loadable({
  loader: () => import('./sign-up'),
  loading: Loading,
});

const News = Loadable({
  loader: () => import('./news'),
  loading: Loading,
});

const Events = Loadable({
  loader: () => import('./events'),
  loading: Loading,
});


const Search = Loadable({
  loader: () => import('./search'),
  loading: Loading,
});

const Service = Loadable({
  loader: () => import('./services'),
  loading: Loading,
});



const Home = Loadable({
  loader: () => import('./home'),
  loading: Loading,
});

const Car = Loadable({
  loader: () => import('./car'),
  loading: Loading,
});

const Booking = Loadable({
  loader: () => import('./booking'),
  loading: Loading,
});

const ViewBook = Loadable({
  loader: () => import('./viewBook'),
  loading: Loading,
});

const ContactUs = Loadable({
  loader: () => import('./contactUs'),
  loading: Loading,
});

const Test = Loadable({
  loader: () => import('./test'),
  loading: Loading,
});

const Rates = Loadable({
  loader: () => import('./rates'),
  loading: Loading,
});

const Partners = Loadable({
  loader: () => import('./partners'),
  loading: Loading,
});

const Profile = Loadable({
  loader: () => import('./profile'),
  loading: Loading,
});
const ForgotPassword = Loadable({
  loader: () => import('./forgotPassword'),
  loading: Loading,
});

const Event = Loadable({
  loader: () => import('./event'),
  loading: Loading,
});



class index extends Component {

  componentWillMount() {

    // const match = matchPath(
    //   history.location.pathname, // like: /course/123
    //   { path: '/:lang' }
    // );

    ///// add specific language /////
    let lang = "en";
    if(VAR_LANG_ID === 2) lang = "fr";
    else if(VAR_LANG_ID === 3) lang = "ru";
    
    store.dispatch(updateIntl({
      locale: lang,
      messages: messages[lang],
    }))
    ///// add specific language /////
  }

  render() {

    return (
      <div>

        {/* <Nav></Nav>
                <Authentication></Authentication> */}
        {/* <TransitionGroup>
          <CSSTransition
            key={history.location.key}
            timeout={{ enter: 300, exit: 300 }}
            classNames={'fade'}
          > */}
        <Switch >
          <Route path="/" exact component={Home} />
          {
          (VAR_LANG_ID === 1) &&
          <Route path="/rent/:id" exact component={Car} />
          }{
          (VAR_LANG_ID === 2) &&
          <Route path="/location/:id" exact component={Car} />
          }

          <Route path={"/"+BOOKING_ROUTE+"/:service/:id"} exact component={Booking} />
          <Route path={"/"+BOOK_ROUTE+"/:id"} exact component={ViewBook} />
          {/*<Route path="/search" exact component={Search} />*/}
          <Route path="/service/:id" exact component={Service} />
          <Route path="/test" exact component={Test} />
          <Route path={"/"+LOGIN_ROUTE} exact component={LoginPage} />
          <Route path={"/"+SIGNUP_ROUTE} exact component={SignUp} />
          <Route path="/contact" exact component={ContactUs} />
          <Route path={"/"+RATES_ROUTE+"/:service"} exact component={Rates} />
          <Route path={"/"+NEWS_ROUTE} exact component={News} />
          <Route path={"/"+NEWS_ROUTE+"/:id"} exact component={News} />
          <Route path={"/"+EVENT_ROUTE+"/:city?"} exact component={Events} />
          <Route path={"/"+EVENT_ROUTE+"/:city/:id"} exact component={Event} />
          <Route path="/partners" exact component={Partners} />
          <Route path={"/"+PROFILE_ROUTE} exact component={Profile} />
          <Route path="/forgotPassword/:id" exact component={ForgotPassword} />

          <Route path="/:paramOne?/:paramTwo?/:paramThree?/:paramFour?" exact component={Search} />

        </Switch>
        {/* </CSSTransition>
        </TransitionGroup> */}

      </div>
    );
  }
}

export default index;