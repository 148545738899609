import axios from '../utils/axiosInstance';
import {authHeader} from '../helpers/auth-header'
import {sendRequestDotNet} from '../utils/axiosdotnet'


var Bearer = authHeader();


export function deleteBooking(request) {
    return sendRequestDotNet('/api/Booking/BookingDelete', request,{
        headers: Bearer
      })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            throw error;
        });
}

export function BookingHistory(request) {
    return sendRequestDotNet('/api/Booking/BookingHistory', request,{
        headers: Bearer
      })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            throw error;
        });
}



export function ratesByService(request) {
    return sendRequestDotNet('/api/Rate/RatesByService', request,{
        headers: Bearer
      })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            throw error;
        });
}

export function book(request) {
    return sendRequestDotNet('/api/Booking/Book', request,{
    headers: Bearer
  })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            throw error;
        });
}

export function updateBookCar(request) {
    return sendRequestDotNet('/api/Booking/BookingUpdate', request,{
        headers: Bearer
      })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            throw error;
        });
}



export function getPricingDetails(request) {
    return new Promise(function (resolve, reject) {
        setTimeout(function () {
            resolve({
                pricing: {
                    extraServices: [
                        {
                            idExtraService: 0,
                            price: "10 $",
                            title: "Baby Seat"
                        },
                        {
                            idExtraService: 1,
                            price: "20 $",
                            title: "Child Booster"
                        }, {
                            idExtraService: 2,
                            price: "30 $",
                            title: "Exsess Protection"
                        },
                        {
                            idExtraService: 3,
                            price: "40 $",
                            title: "Wifi"
                        }
                    ],
                    vehicleName: "Car Name",
                    imageUrl: "http://api.developmenthouse.net/AAA/CmsApi/api/Main/DisplayImage?section=Vehicle&id=2",
                    idBrand: 1,
                    service: "string",
                    price: "1000",
                    discountedPrice: "800"
                },
                statusCode: {
                    code: 0,
                    message: "string"
                }
            });
        }, 300);
    });
}


export function getRentalPricingDetails(request) {
    return sendRequestDotNet('/api/Booking/CarRentalPrice', request,{
        headers: Bearer
      })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            throw error;
        });
}

export function getChaufeuredPricingDetails(request) {

    return sendRequestDotNet('/api/Booking/ChauffeuredPrice', request,{
        headers: Bearer
      })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            throw error;
        });
}
export function getShuttlePricingDetails(request) {

    return sendRequestDotNet('/api/Booking/ShuttledPrice', request,{
        headers: Bearer
      })
        .then(function (response) {

            return response.data;
        })
        .catch(function (error) {
            throw error;
        });
}