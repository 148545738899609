import { FETCH_MAIN_NEWS,FETCH_NEWS_PAGE,FETCH_SINGLE_NEWS } from "./actions-types";


export function fetchNews(id) {
    return { type: FETCH_SINGLE_NEWS, payload:{id} } ;
}

export function fetchNewsPage() {
    return { type: FETCH_NEWS_PAGE};
}

export function fetchMainNews() {
    return { type: FETCH_MAIN_NEWS};
}
