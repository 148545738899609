import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
    FETCH_PROFILE, 
    FETCH_PROFILE_SUCCESS, 
    FETCH_PROFILE_FAILED,
    UPDATE_PROFILE,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE_FAILED
} from '../actions/actions-types';
import {  getUserProfile ,updateProfile} from '../../datasource/profile.datasource';


// const getUserProfile = (state) => state.userProfile;

function* fetchUserProfile(action) {
    try { 
        let { id } = action.payload; 
        const userProfile = yield call(getUserProfile, { idUser: id}); 

        yield put({ type: FETCH_PROFILE_SUCCESS, payload: userProfile});
    } catch (error) {
        console.error(error)
        yield put({ type: FETCH_PROFILE_FAILED, payload: { error} });
    }
}
function* fetchUpdateProfile(action) {
    try { 
         
        const userProfile = yield call(updateProfile, action.payload); 

        yield put({ type: UPDATE_PROFILE_SUCCESS, payload: userProfile});
    } catch (error) { 
        yield put({ type: UPDATE_PROFILE_FAILED, payload: { error} });
    }
}
  
function* fetchUserProfileSaga() {
    yield takeEvery(FETCH_PROFILE, fetchUserProfile);
}  
function* fetchUpdateProfileSaga() {
    yield takeEvery(UPDATE_PROFILE, fetchUpdateProfile);
}  
export default function* rootSaga() {
    yield all([fork(fetchUserProfileSaga)]); 
    yield all([fork(fetchUpdateProfileSaga)]); 
}