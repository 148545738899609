
import {
    FETCH_TODO_LIST,
    TODO_LIST_FETCHED_SUCCESSFULLY,
    TODO_LIST_FETCH_FAILED
} from '../actions/actions-types'

const INIT_STATE = {
    loaded: false,
    loading: false,
    data: []
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_TODO_LIST: {
            return {
                ...state, loading:true,loaded:false
            }
        }
        case TODO_LIST_FETCHED_SUCCESSFULLY: {
            return {
                ...state, data: action.payload.data,loading:false,loaded:true
            }
        }
        case TODO_LIST_FETCH_FAILED: {
            return {
                ...state, loading:false,loaded:false
            }
        }
        default:
        return state;
    }
}